import { CampaingList } from "@/interfaces/campaign";
import { ItemGraphic } from "@/interfaces/graphic";
import { FilterItem, IFiltersObject } from "@/interfaces/persons/v10/audience";
import {
	Action,
	ActionLoading,
	ActionResponse,
	AnalyzeResponse,
	Audience,
	ElementData,
	Geo,
	GeoFilterData,
	GraphicAudienceType,
	GraphicData,
	GraphicGeoType,
	GraphicPoisType,
	GraphicReach,
	GraphicResponse,
	PersistentPoisData,
	Pois,
	Private,
	PoisFilterData,
	PrivateFilterData,
	StoreAttribution,
	Pagination,
	PersonResource,
	UseCaseVisibility,
	OohFilterData,
	Ooh,
	SearchFilter,
	SearchFilterGeo,
	SearchFilterPois,
	SearchFilterPrivates,
	SearchFilterOoh,
	ActivePanelNext,
	PosFilterData,
	Pos,
	SearchFilterPos,
} from "@/interfaces/persons/v10/person";
import {
	MatchedRoute,
	MatchedRouteKey,
	ResultContinent,
	ResultData,
	SelectStrategy,
	Sluggable,
	StoreAttributionResponse,
} from "@/interfaces/persons/v10/response";
import {
	FetchFilter,
	ListOption,
	TabSelectionOptional,
} from "@/interfaces/persons/v10/tabs/pois";
import {
	ActivePanelTab,
	ButtonActionType,
	FilterKeySlice,
	MatchedRouteType,
	PersonFilterType,
	PersonGeoKey,
	PersonKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPosKey,
	PersonPrivateKey,
	PersonStrategyKey,
	PersonUsesCasesKey,
	StrategyTitleID,
} from "@/interfaces/persons/v10/types";
import {
	getFromStorage,
	removeFromStorage,
	setPersistentCampaigns,
	setPersistentCountry,
	storeIndex,
} from "@/services/storage-service";
import { convLocaleString, slugify } from "@/utils/convert";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import { isEmpty, isUndefined } from "lodash";
import { ExpansionPanel } from "./Tabs/Pois/Expansions/Pois";
import { isProd } from "@/services/process-service";

const QUINTILS_DEFAULT = [{
	id: 0,
	value: "0"
}, {
	id: 5,
	value: "5"
}];

export class SelectedDataEntity implements ElementData {
	id: number = NaN;
	value: string = "";
	extra?: string = "";
	count?: number;
	type?: string = "";
	types?: string[] = [];
	categoria?: string;
	subcategoria?: string;
	marca?: string;
	nombre?: string;
	checked?: Boolean = false;
	identifier?: string = "";

	constructor(parameters?: ElementData) {
		if (parameters) {
			this.id = parameters.id || this.id;
			this.value = parameters.value || this.value;
			this.type = parameters.type || this.type;
			this.count = parameters.count || this.count;
			this.categoria = parameters.categoria || this.categoria;
			this.subcategoria = parameters.subcategoria || this.subcategoria;
			this.marca = parameters.marca || this.marca;
			this.nombre = parameters.nombre || this.nombre;
			this.checked = Boolean(parameters.checked || this.checked);
		}
		this.identifier = this.getIdentifier();
		//this.log();
	}

	hasExtra() {
		return (
			!isEmpty(this.categoria) ||
			!isEmpty(this.subcategoria) ||
			!isEmpty(this.marca) ||
			!isEmpty(this.nombre)
		);
	}

	getToString(): string {
		if (!this.hasExtra()) return this.value;
		const extras = [
			this.nombre,
			this.marca,
			this.subcategoria,
			this.categoria,
		];
		const extraText = extras.find((extra) => !isEmpty(extra));
		return `${this.value} / ${extraText}`;
	}

	concatParameters(): string {
		const params = [
			this.value,
			this.type,
			this.categoria,
			this.subcategoria,
			this.marca,
			this.nombre,
		];

		return params.filter((param) => !!param).join("_");
	}

	getIdentifier() {
		let parametters = this.concatParameters();
		let slug = slugify(parametters);
		return slug;
	}

	setExtra(extra: string | undefined): void {
		this.extra = extra;
	}

	setChecked(checked: Boolean = false): void {
		this.checked = checked;
	}

	log() {
		//if (isNaN(this.id)) return;
		console.log(`identifier: ${this.identifier}`, {
			id: this.id,
			type: this.type,
			value: this.value,
			categoria: this.categoria,
			subcategoria: this.subcategoria,
			marca: this.marca,
			nombre: this.nombre,
		});
	}
}

/**
 * Geo
 */

export class PersonGeoEntity implements Geo {
	states: ElementData[] = [];
	cities: ElementData[] = [];
	neighborhoods: ElementData[] = [];
	constructor() {}
}

/**
 * Pois
 */

export class PersonPoisEntity implements Pois {
	categories: ElementData[] = [];
	subcategories: ElementData[] = [];
	brands: ElementData[] = [];
	names: ElementData[] = [];
	constructor() {}
}

/**
 * Private
 */

export class PersonPrivateEntity implements Private {
	privates: ElementData[] = [];
	constructor() {}
}

/**
 * Private
 */

export class PersonOohEntity implements Ooh {
	ooh_categories: ElementData[] = [];
	ooh_subcategories: ElementData[] = [];
	ooh_brands: ElementData[] = [];
	ooh_names: ElementData[] = [];
	constructor() {}
}

/**
 * Pos
 */

export class PersonPosEntity implements Pos {
	[PersonPosKey.CHIPPER_STATES]: ElementData[] = [];
	[PersonPosKey.CHIPPER_CITIES]: ElementData[] = [];
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: ElementData[] = [];
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: ElementData[] = [];
	[PersonPosKey.CHIPPER_CATEGORIES]: ElementData[] = [];
	[PersonPosKey.CHIPPER_COMPANIES]: ElementData[] = [];
	[PersonPosKey.CHIPPER_BRANDS]: ElementData[] = [];
	[PersonPosKey.CHIPPER_NAMES_SKU]: ElementData[] = [];
	[PersonPosKey.CHIPPER_STORES_TYPE]: ElementData[] = [];
	[PersonPosKey.CHIPPER_QUINTILS]: ElementData[] = QUINTILS_DEFAULT;
	constructor() {}

	resetQuintils() {
		this[PersonPosKey.CHIPPER_QUINTILS] = QUINTILS_DEFAULT;
	}
}

export class GeoFilterDataEntity implements GeoFilterData {
	selected: PersonGeoEntity = new PersonGeoEntity();
	and: PersonGeoEntity = new PersonGeoEntity();
	pre: PersonGeoEntity = new PersonGeoEntity();
	constructor(properties?: GeoFilterDataEntity) {
		if (properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class PoisFilterDataEntity implements PoisFilterData {
	selected: Pois = new PersonPoisEntity();
	and: Pois = new PersonPoisEntity();
	pre: Pois = new PersonPoisEntity();
	constructor(properties?: PoisFilterDataEntity) {
		if (properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class PrivateFilterDataEntity implements PrivateFilterData {
	selected: Private = new PersonPrivateEntity();
	and: Private = new PersonPrivateEntity();
	pre: Private = new PersonPrivateEntity();
	constructor(properties?: PrivateFilterDataEntity) {
		if (properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class OohFilterDataEntity implements OohFilterData {
	selected: Ooh = new PersonOohEntity();
	and: Ooh = new PersonOohEntity();
	pre: Ooh = new PersonOohEntity();
	constructor(properties?: OohFilterDataEntity) {
		if (properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class PosFilterDataEntity implements PosFilterData {
	selected: PersonPosEntity = new PersonPosEntity();
	and: PersonPosEntity = new PersonPosEntity();
	pre: PersonPosEntity = new PersonPosEntity();
	constructor(properties?: PosFilterDataEntity) {
		if (properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

/**
 * Audience
 */

export class PersonAudienceEntity implements Audience {
	constructor() {}
}

/**
 * StoreAttribution
 */

export class PersonStoreAttributionEntity implements StoreAttribution {
	loading: Boolean = false;
	open: Boolean = false;
	check: Boolean = false;
	selected: CampaingList[] = [];
	response: ResultDataEntity = new ResultDataEntity();

	constructor(properties?: PersonStoreAttributionEntity) {
		if (properties) {
			this.loading = properties.loading;
			this.open = properties.open;
			this.selected = properties.selected;
			this.response = new ResultDataEntity(properties.response);
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}

	setCheck(check: Boolean = false) {
		this.check = check;
	}

	setSelected(selected: CampaingList[] = []) {
		this.selected = selected;
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}

	async setResponse(response: ResultDataEntity) {
		this.response = response;
	}

	getResponse() {
		return this.response.response;
	}

	showMaidUnique(): Boolean {
		const items: StoreAttributionResponse[] =
			(this.getResponse() as StoreAttributionResponse[]) || [];
		return !isEmpty(items);
	}

	getMaidUnique() {
		const items: StoreAttributionResponse[] =
			(this.getResponse() as StoreAttributionResponse[]) || [];
		return items.map((i) => ({
			count: `${i.key}: ${convLocaleString(i.value)}`,
		}));
	}
}

export class PersonTargetAudienceEntity {
	open: Boolean = false;

	constructor(properties?: PersonTargetAudienceEntity) {
		if (properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class PersonSavePoisEntity {
	open: Boolean = false;

	constructor(properties?: PersonSavePoisEntity) {
		if (properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class PersonGeoFencingEntity {
	open: Boolean = false;

	constructor(properties?: PersonGeoFencingEntity) {
		if (properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class PersonActivateOOHEntity {
	open: Boolean = false;
	loading: Boolean = false;

	constructor(properties?: PersonActivateOOHEntity) {
		if (properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}
}

export class ActionEntity implements Action {
	analyze_pois: Boolean = false;
	analyze_geo: Boolean = false;
	analyze_pos: Boolean = false;
	analyze_audience: Boolean = false;
	calculate_reach: Boolean = false;
	geo_fencing: Boolean = false;
	geo_report: Boolean = false;
	target_audience: Boolean = false;
	store_attribution: Boolean = false;
	save_pois: Boolean = false;
	activate_ooh: Boolean = false;

	constructor() {}
}

export class AnalyzeResponseEntity implements AnalyzeResponse {
	[ButtonActionType.ANALYZE_POIS]: ResultDataEntity = new ResultDataEntity();
	[ButtonActionType.ANALYZE_GEO]: ResultDataEntity = new ResultDataEntity();
	[ButtonActionType.ANALYZE_POS]: ResultDataEntity = new ResultDataEntity();
	analyze_audience: ResultDataEntity = new ResultDataEntity();
	calculate_reach: ResultDataEntity = new ResultDataEntity();
	geo_fecing: ResultDataEntity = new ResultDataEntity();
	geo_report: ResultDataEntity = new ResultDataEntity();
	target_audience: ResultDataEntity = new ResultDataEntity();
	store_attribution: ResultDataEntity = new ResultDataEntity();
	save_pois: ResultDataEntity = new ResultDataEntity();

	constructor(properties?: AnalyzeResponseEntity) {
		if (properties) {
			this[ButtonActionType.ANALYZE_POIS] = new ResultDataEntity(
				properties.analyze_pois
			);
			this[ButtonActionType.ANALYZE_GEO] = new ResultDataEntity(
				properties.analyze_geo
			);
			this[ButtonActionType.ANALYZE_POS] = new ResultDataEntity(
				properties.analyze_pos
			);
			this.analyze_audience = new ResultDataEntity(
				properties.analyze_audience
			);
			this.calculate_reach = new ResultDataEntity(
				properties.calculate_reach
			);
			this.geo_fecing = new ResultDataEntity(properties.geo_fecing);
			this.geo_report = new ResultDataEntity(properties.geo_report);
			this.target_audience = new ResultDataEntity(
				properties.target_audience
			);
			this.store_attribution = new ResultDataEntity(
				properties.store_attribution
			);
			this.save_pois = new ResultDataEntity(properties.save_pois);
		}
	}
}

export class ResultDataEntity implements ResultData {
	success: Boolean = false;
	response?: Object | Boolean | StoreAttributionResponse[] | null = null;
	message: string = "";

	constructor(params?: ResultData) {
		if (params) {
			this.success = params.success;
			this.response = params.response;
			this.message = params.message;
		}
	}

	isValid(): Boolean {
		if (this.response === null) {
			return false;
		}

		if (typeof this.response === "boolean") {
			return this.response && this.success;
		}

		if (Array.isArray(this.response)) {
			return this.response.length > 0 && this.success;
		}

		if (typeof this.response === "object" && this.response !== null) {
			return Object.keys(this.response).length > 0 && this.success;
		}

		return false;
	}
}

export class ActionLoadingEntity implements ActionLoading {
	action: Action = new ActionEntity();

	constructor() {}
}

export class ActionResponseEntity implements ActionResponse {
	action: AnalyzeResponseEntity = new AnalyzeResponseEntity();

	constructor(properties?: ActionResponse) {
		if (properties) {
			this.action = new AnalyzeResponseEntity(properties.action);
		}
	}

	setResponse(key: ButtonActionType, response: ResultData) {
		this.action[key] = response;
	}

	getResponse(key: ButtonActionType): ResultDataEntity {
		return this.action[key];
	}

	isValidResponse(key: ButtonActionType): Boolean {
		const response: ResultDataEntity = new ResultDataEntity(
			this.action[key]
		);
		return response?.isValid();
	}
}

export class GraphicDataEntity implements GraphicData {
	loading: Boolean = false;
	data: ItemGraphic[] = [];

	constructor(properties?: GraphicDataEntity) {
		if (properties) {
			this.loading = properties.loading;
			this.data = properties.data;
		}
	}
}

export class GraphicGeoTypeEntity implements GraphicGeoType {
	states: GraphicData = new GraphicDataEntity();
	cities: GraphicData = new GraphicDataEntity();
	neighborhoods: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicGeoTypeEntity) {
		if (properties) {
			this.states = new GraphicDataEntity(properties.states);
			this.cities = new GraphicDataEntity(properties.cities);
			this.neighborhoods = new GraphicDataEntity(
				properties.neighborhoods
			);
		}
	}
}

export class GraphicPoisTypeEntity implements GraphicPoisType {
	categories: GraphicData = new GraphicDataEntity();
	subcategories: GraphicData = new GraphicDataEntity();
	brands: GraphicData = new GraphicDataEntity();
	states: GraphicData = new GraphicDataEntity();
	cities: GraphicData = new GraphicDataEntity();
	neighborhoods: GraphicData = new GraphicDataEntity();
	pois: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicPoisTypeEntity) {
		if (properties) {
			this.categories = new GraphicDataEntity(properties.categories);
			this.subcategories = new GraphicDataEntity(
				properties.subcategories
			);
			this.brands = new GraphicDataEntity(properties.brands);
			this.states = new GraphicDataEntity(properties.states);
			this.cities = new GraphicDataEntity(properties.cities);
			this.neighborhoods = new GraphicDataEntity(
				properties.neighborhoods
			);
			this.pois = new GraphicDataEntity(properties.pois);
		}
	}
}

export class GraphicAudienceTypeEntity implements GraphicAudienceType {
	constructor() {}
}

export class GraphicReachEntity implements GraphicReach {
	geo: GraphicData = new GraphicDataEntity();
	pois: GraphicData = new GraphicDataEntity();
	pos: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicReachEntity) {
		if (properties) {
			this.geo = new GraphicDataEntity(properties.geo);
			this.pois = new GraphicDataEntity(properties.pois);
			this.pos = new GraphicDataEntity(properties.pos);
		}
	}
}

export class GraphicResponseEntity implements GraphicResponse {
	pois: GraphicPoisType = new GraphicPoisTypeEntity();
	pos: GraphicGeoType = new GraphicGeoTypeEntity();
	geo: GraphicGeoType = new GraphicGeoTypeEntity();
	audience: GraphicAudienceType = new GraphicAudienceTypeEntity();
	reach: GraphicReach = new GraphicReachEntity();

	constructor(properties?: GraphicResponseEntity) {
		if (properties) {
			this.pois = new GraphicPoisTypeEntity(properties.pois);
			this.geo = new GraphicGeoTypeEntity(properties.geo);
			this.pos = new GraphicGeoTypeEntity(properties.pos);
			this.audience = new GraphicAudienceTypeEntity();
			this.reach = new GraphicReachEntity(properties.reach);
		}
	}

	isReachedPois() {
		return this.reach.pois.data.length > 0;
	}
}

export class GraphicEntity<T = ItemGraphic, V = () => boolean> {
	source: T[] = [];
	loading = false;
	validator: V;

	constructor(validator?: V) {
		if (typeof validator === "function") {
			this.validator = validator;
		} else {
			this.validator = (() => true) as unknown as V;
		}
	}
}

export class FilterEntity implements IFiltersObject {
	poi_distance: FilterItem[] = [];
	user_type: FilterItem[] = [];
	gender: FilterItem[] = [];
	age: FilterItem[] = [];
	niv_socio: FilterItem[] = [];
	category_poi: FilterItem[] = [];
	sub_category_poi: FilterItem[] = [];
	marca_poi: FilterItem[] = [];
	dpto_poi: FilterItem[] = [];
	city_poi: FilterItem[] = [];
	barrio_poi: FilterItem[] = [];
	date: FilterItem[] = [];
	// date_of_week: FilterItem[] = [];
	// time_of_day: FilterItem[] = [];
	frequency: FilterItem[] = [];
	residence_dpto: FilterItem[] = [];
	residence_city: FilterItem[] = [];
	residence_barrio: FilterItem[] = [];
	iab: FilterItem[] = [];
	interest: FilterItem[] = [];
	sites: FilterItem[] = [];
	app_bundle: FilterItem[] = [];
	app_name: FilterItem[] = [];
	content_language: FilterItem[] = [];
	city_connection: FilterItem[] = [];
	carrier: FilterItem[] = [];
	device_type: FilterItem[] = [];
	make: FilterItem[] = [];
	browser: FilterItem[] = [];
	os: FilterItem[] = [];
}

export type FilterKeys = keyof FilterEntity;

export class PersistentPoisDataEntity implements PersistentPoisData {
	country_global: SelectedDataEntity = new SelectedDataEntity();
	campaigns: ComboListOptionsCampaign[] = [];

	constructor() {
		const country_global = getFromStorage(storeIndex.COUNTRY_GLOBAL);
		const campaigns = getFromStorage(storeIndex.CAMPAIGNS);

		if (country_global) {
			this.country_global = JSON.parse(country_global);
		}

		if (campaigns) {
			this.campaigns = JSON.parse(campaigns);
		}
	}

	setCountry(country: SelectedDataEntity) {
		setPersistentCountry(country);
		this.country_global = country;
	}

	setCampaigns(campaigns: ComboListOptionsCampaign[] = []) {
		setPersistentCampaigns(campaigns);
		this.campaigns = campaigns;
	}

	clearCampaigns() {
		removeFromStorage(storeIndex.CAMPAIGNS);
		this.campaigns = [] as ComboListOptionsCampaign[];
	}

	getCountry() {
		return this.country_global;
	}
}

export class ListOptionEntity implements ListOption {
	id: number = NaN;
	value: String = "";
	disabled: Boolean = true;

	constructor(id: number, value: String, disabled: Boolean = true) {
		this.id = id;
		this.value = value;
		this.disabled = disabled;
	}
}

export class PersonResourceEntity implements PersonResource {
	countries: ResultContinent[] = [];
	api_strategies: SluggableEntity[] = [];
	api_use_cases: SluggableEntity[] = [];
	strategies: SelectStrategy[] = [];
	uses_cases: ElementData[] = [];
	audience_type: ListOptionEntity[] = [];

	constructor(properties?: PersonResourceEntity) {
		if (properties) {
			this.countries = properties.countries;
			this.strategies = properties.strategies;
			this.uses_cases = properties.uses_cases;
			this.audience_type = properties.audience_type;
		}
	}

	async setResource<T>(key: string, value: T) {
		this[key] = value;
	}

	createStrategies() {
		this.strategies = this.api_strategies.map((s) => {
			return new SelectStrategyEntity(StrategyTitleID[s.key], [
				{
					id: s.id,
					value: `persons.v10.panelsPois.subPanel.strategy.${s.key}`,
					type: s.key,
				},
			]);
		});
	}

	createUseCases() {
		this.uses_cases = this.api_use_cases.map((s) => {
			return new SluggableEntity({
				id: String(s.id),
				value: s.key,
				types: MATCH_USES_CASES[s.key],
			});
		});
	}
}

export class PaginationEntity implements Pagination {
	totalPages: number = 1;
	itemsPerPage: number = 10;
	page: number = 0;
	constructor() {}

	currentPage(): number {
		return this.page + 1;
	}

	getTotalPages(): number {
		return this.totalPages;
	}

	setLastPage(): void {
		this.page = this.totalPages - 1;
	}

	setPage(page: number = 0): void {
		this.page = page;
	}

	async setTotalPages(count: number): Promise<void> {
		this.totalPages = Math.ceil(count / this.itemsPerPage);
		if (!count) {
			this.setPage();
		}
	}

	nextPage(): void {
		if (this.page < this.totalPages - 1) {
			this.page++;
		}
	}

	previousPage(): void {
		if (this.page > 0) {
			this.page--;
		}
	}

	verifyPage(): void {
		if (this.page === this.totalPages && this.page > 0) {
			this.page--;
		}
	}

	isZero(): Boolean {
		return this.page === 0;
	}

	isEqual(): Boolean {
		return this.page === this.totalPages - 1;
	}

	isZeroColor(): string {
		return this.isZero() ? "" : "secondary";
	}

	isEqualColor(): string {
		return this.isEqual() ? "" : "secondary";
	}

	getPaginatedItems(items: ElementData[]) {
		const start = this.page * this.itemsPerPage;
		const end = start + this.itemsPerPage;
		return items.slice(start, end);
	}
}

export class UseCaseVisibilityEntity implements UseCaseVisibility {
	strategy_type: PersonStrategyKey = PersonStrategyKey.NONE;
	uses_cases_type: PersonUsesCasesKey = PersonUsesCasesKey.NONE;
	tabs: PersonKey[] = [];
	buttons: ButtonActionType[] = [];

	constructor(properties?: UseCaseVisibility) {
		if (properties) {
			this.setVisibility(properties);
		}
	}

	async setVisibility(properties?: UseCaseVisibility) {
		this.strategy_type =
			properties?.strategy_type || PersonStrategyKey.NONE;
		this.uses_cases_type =
			properties?.uses_cases_type || PersonUsesCasesKey.NONE;
		this.tabs = properties?.tabs || [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
		];
		this.buttons = properties?.buttons || [
			ButtonActionType.ANALYZE_POIS,
			ButtonActionType.CALCULATE_REACH,
			ButtonActionType.STORE_ATTR,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.SAVE_POIS,
			ButtonActionType.GEO_FENCING,
			ButtonActionType.TARGET_AUDIENCE,
		];
	}

	/**
	 * Mostrar la seccion de filtros
	 * @param tab
	 * @returns
	 */
	showTab(tab: PersonKey) {
		if (!this.strategy_type) return true;
		return this.tabs.includes(tab);
	}

	/**
	 * Mostrar el boton de accion
	 * @param actionType
	 * @returns
	 */
	showButton(actionType: ButtonActionType) {
		return this.buttons.includes(actionType);
	}
}

/**
 * Utilizado para determinar que propiedades resetear
 * { strategy, uses_cases, geo }
 */
export class TabSelectionOptionalEntity implements TabSelectionOptional {
	[PersonKey.STRATEGY]: Boolean = false;
	[PersonKey.USES_CASES]: Boolean = false;
	[PersonKey.GEO]: Boolean = false;
	[PersonKey.POIS]: Boolean = false;
	[PersonKey.OOH]: Boolean = false;
	[PersonKey.POS]: Boolean = false;
	[PersonKey.PRIVATE]: Boolean = false;

	constructor(properties?: {
		strategy?: Boolean;
		uses_cases?: Boolean;
		geo?: Boolean;
		pois?: Boolean;
		ooh?: Boolean;
		pos?: Boolean;
		privates?: Boolean;
	}) {
		if (properties) {
			this.strategy = properties.strategy || false;
			this.uses_cases = properties.uses_cases || false;
			this.geo = properties.geo || false;
			this.pois = properties.pois || false;
			this.ooh = properties.ooh || false;
			this.pos = properties.pos || false;
			this.privates = properties.privates || false;
		}
	}
}

export class SearchFilterEntity implements SearchFilter {
	geo: SearchFilterGeo = new SearchFilterGeoEntity();
	pois: SearchFilterPois = new SearchFilterPoisEntity();
	privates: SearchFilterPrivates = new SearchFilterPrivatesEntity();
	ooh: SearchFilterOoh = new SearchFilterOohEntity();
	pos: SearchFilterPos = new SearchFilterPosEntity();

	constructor() {}

	setData(type: string, key: string, value: string) {
		this[type][key] = value;
	}
}

export class SearchFilterGeoEntity implements SearchFilterGeo {
	[PersonGeoKey.STATES]: string = "";
	[PersonGeoKey.CITIES]: string = "";
	[PersonGeoKey.NEIGHBORHOODS]: string = "";

	constructor(properties?: {
		[PersonGeoKey.STATES]?: string;
		[PersonGeoKey.CITIES]?: string;
		[PersonGeoKey.NEIGHBORHOODS]?: string;
	}) {
		if (properties) {
			this[PersonGeoKey.STATES] = properties[PersonGeoKey.STATES] || "";
			this[PersonGeoKey.CITIES] = properties[PersonGeoKey.CITIES] || "";
			this[PersonGeoKey.NEIGHBORHOODS] =
				properties[PersonGeoKey.NEIGHBORHOODS] || "";
		}
	}
}

export class SearchFilterPoisEntity implements SearchFilterPois {
	[PersonPoisKey.CATEGORIES]: string = "";
	[PersonPoisKey.SUBCATEGORIES]: string = "";
	[PersonPoisKey.BRANDS]: string = "";
	[PersonPoisKey.NAMES]: string = "";

	constructor(properties?: {
		[PersonPoisKey.CATEGORIES]?: string;
		subcategories?: string;
		brands?: string;
		names?: string;
	}) {
		if (properties) {
			this[PersonPoisKey.CATEGORIES] =
				properties[PersonPoisKey.CATEGORIES] || "";
			this[PersonPoisKey.SUBCATEGORIES] =
				properties[PersonPoisKey.SUBCATEGORIES] || "";
			this[PersonPoisKey.BRANDS] = properties[PersonPoisKey.BRANDS] || "";
			this[PersonPoisKey.NAMES] = properties[PersonPoisKey.NAMES] || "";
		}
	}
}

export class SearchFilterOohEntity implements SearchFilterOoh {
	[PersonOohKey.OOH_CATEGORIES]: string = "";
	[PersonOohKey.OOH_SUBCATEGORIES]: string = "";
	[PersonOohKey.OOH_BRANDS]: string = "";
	[PersonOohKey.OOH_NAMES]: string = "";

	constructor(properties?: SearchFilterOoh) {
		if (properties) {
			this[PersonOohKey.OOH_CATEGORIES] = properties[PersonOohKey.OOH_CATEGORIES] || "";
			this[PersonOohKey.OOH_SUBCATEGORIES] = properties[PersonOohKey.OOH_SUBCATEGORIES] || "";
			this[PersonOohKey.OOH_BRANDS] = properties[PersonOohKey.OOH_BRANDS] || "";
			this[PersonOohKey.OOH_BRANDS] = properties[PersonOohKey.OOH_BRANDS] || "";
		}
	}
}

export class SearchFilterPosEntity implements SearchFilterPos {
	[PersonPosKey.CHIPPER_STATES]: string = "";
	[PersonPosKey.CHIPPER_CITIES]: string = "";
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: string = "";
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: string = "";
	[PersonPosKey.CHIPPER_CATEGORIES]: string = "";
	[PersonPosKey.CHIPPER_COMPANIES]: string = "";
	[PersonPosKey.CHIPPER_BRANDS]: string = "";
	[PersonPosKey.CHIPPER_NAMES_SKU]: string = "";
	[PersonPosKey.CHIPPER_STORES_TYPE]: string = "";

	constructor(properties?: SearchFilterPos) {
		if (properties) {
			this[PersonPosKey.CHIPPER_STATES] =
				properties[PersonPosKey.CHIPPER_STATES] || "";

			this[PersonPosKey.CHIPPER_CITIES] =
				properties[PersonPosKey.CHIPPER_CITIES] || "";

			this[PersonPosKey.CHIPPER_NEIGHBORHOODS] =
				properties[PersonPosKey.CHIPPER_NEIGHBORHOODS] || "";

			this[PersonPosKey.CHIPPER_MACRO_CATEGORIES] =
				properties[PersonPosKey.CHIPPER_MACRO_CATEGORIES] || "";

			this[PersonPosKey.CHIPPER_CATEGORIES] =
				properties[PersonPosKey.CHIPPER_CATEGORIES] || "";

			this[PersonPosKey.CHIPPER_COMPANIES] =
				properties[PersonPosKey.CHIPPER_COMPANIES] || "";

			this[PersonPosKey.CHIPPER_BRANDS] =
				properties[PersonPosKey.CHIPPER_BRANDS] || "";

			this[PersonPosKey.CHIPPER_NAMES_SKU] =
				properties[PersonPosKey.CHIPPER_NAMES_SKU] || "";

			this[PersonPosKey.CHIPPER_STORES_TYPE] =
				properties[PersonPosKey.CHIPPER_STORES_TYPE] || "";
		}
	}
}

export class SearchFilterPrivatesEntity implements SearchFilterPrivates {
	[PersonPrivateKey.PRIVATES]: string = "";

	constructor(properties?: { [PersonPrivateKey.PRIVATES]?: string }) {
		if (properties) {
			this[PersonPrivateKey.PRIVATES] = properties[PersonPrivateKey.PRIVATES] || "";
		}
	}
}

export class ExpansionPanelEntity implements ExpansionPanel {
	id: ActivePanelTab = ActivePanelTab.NONE;
	title: string = "";
	icon: string = "";
	type: PersonKey = PersonKey.NONE;
	disabled: Boolean = false;
	expandable: Boolean = false;
	required: Boolean = false;
	loading: Boolean = false;
	verify: Boolean = false;
	show?: Boolean = true;

	constructor(properties?: ExpansionPanel) {
		if (properties) {
			this.id = properties.id;
			this.title = properties.title || "";
			this.icon = properties.icon || "";
			this.type = properties.type || PersonKey.NONE;
			this.disabled = Boolean(properties.disabled);
			this.expandable = Boolean(properties.expandable);
			this.required = Boolean(properties.required);
			this.loading = Boolean(properties.loading);
			this.verify = Boolean(properties.verify);
			this.show = Boolean(properties.show);
		}
	}
}

export class ActivePanelNextEntity implements ActivePanelNext {
	enable?: PersonKey = undefined;
	activePanel?: ActivePanelTab = undefined;

	constructor(properties?: ActivePanelNext) {
		if (properties) {
			this.enable = properties.enable;
			this.activePanel = properties.activePanel;
		}
	}

	setActivePanel(activePanel: ActivePanelTab) {
		this.activePanel = activePanel;
	}

	setEnable(enable: PersonKey) {
		this.enable = enable;
	}

	hasData() {
		return Boolean(this.activePanel) || Boolean(this.enable);
	}

	debugLog() {
		if (isProd()) return;
		if (!this.hasData()) return;
		console.debug(
			`[ActivePanelNextEntity] activePanel: ${this.activePanel} - enable: ${this.enable}`
		);
	}
}

export class SelectStrategyEntity implements SelectStrategy {
	title: string = "";
	items: ElementData[] = [];

	constructor(title?: string, items?: ElementData[]) {
		if (title && !isUndefined(items)) {
			this.setTitle(title);
			this.items = items;
		}
	}

	setTitle(type: string) {
		this.title = `persons.v10.panelsPois.subPanel.${type}.title`;
	}
}

export class MatchedRouteEntity implements MatchedRoute {
	countries: MatchedRouteKeyEntity = new MatchedRouteKeyEntity();
	audiente_type: MatchedRouteKeyEntity = new MatchedRouteKeyEntity();

	/**
	 * GEO
	 */
	[PersonGeoKey.STATES]: MatchedRouteKeyEntity = new MatchedRouteKeyEntity({
		key: MatchedRouteType.INDEX,
		value: MatchedRouteType.KEY,
	});
	[PersonGeoKey.CITIES]: MatchedRouteKeyEntity = new MatchedRouteKeyEntity({
		key: MatchedRouteType.INDEX,
		value: MatchedRouteType.KEY,
	});
	[PersonGeoKey.NEIGHBORHOODS]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
		});

	/**
	 * POIS
	 */
	[PersonPoisKey.CATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});
	[PersonPoisKey.SUBCATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
		});
	[PersonPoisKey.BRANDS]: MatchedRouteKeyEntity = new MatchedRouteKeyEntity({
		key: MatchedRouteType.INDEX,
		value: MatchedRouteType.KEY,
		count: MatchedRouteType.VALUE,
		categoria: MatchedRouteType.KEY2,
		subcategoria: MatchedRouteType.KEY3,
	});
	[PersonPoisKey.NAMES]: MatchedRouteKeyEntity = new MatchedRouteKeyEntity({
		key: MatchedRouteType.INDEX,
		value: MatchedRouteType.KEY,
		count: MatchedRouteType.VALUE,
		categoria: MatchedRouteType.KEY2,
		subcategoria: MatchedRouteType.KEY3,
	});

	/**
	 * PRIVATES
	 */
	[PersonPrivateKey.PRIVATES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.KEY1,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});

	/**
	 * OOH
	 */
	[PersonOohKey.OOH_CATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});
	[PersonOohKey.OOH_SUBCATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
		});
	[PersonOohKey.OOH_BRANDS]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		});
	[PersonOohKey.OOH_NAMES]: MatchedRouteKeyEntity = new MatchedRouteKeyEntity(
		{
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		}
	);

	/**
	 * POS
	 */
	[PersonPosKey.CHIPPER_STATES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});
	[PersonPosKey.CHIPPER_CITIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});

	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
		});
	[PersonPosKey.CHIPPER_CATEGORIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
		});
	[PersonPosKey.CHIPPER_COMPANIES]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		});
	[PersonPosKey.CHIPPER_BRANDS]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		});
	[PersonPosKey.CHIPPER_NAMES_SKU]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		});
	[PersonPosKey.CHIPPER_STORES_TYPE]: MatchedRouteKeyEntity =
		new MatchedRouteKeyEntity({
			key: MatchedRouteType.INDEX,
			value: MatchedRouteType.KEY,
			count: MatchedRouteType.VALUE,
			categoria: MatchedRouteType.KEY2,
			subcategoria: MatchedRouteType.KEY3,
		});

	constructor() {}

	log(type: PersonFilterType) {
		console.log(`log:: ${type}`, {
			prepareMatchedData: this.prepareMatchedData(type),
		});
	}

	prepareMatchedData(type: PersonFilterType) {
		return {
			states: this.states,
			id: this[type].key,
			value: this[type].value,
			count: this[type].count,
			categoria: this[type].categoria,
			subcategoria: this[type].subcategoria,
			marca: this[type].marca,
			type,
		};
	}
}

export class MatchedRouteKeyEntity implements MatchedRouteKey {
	key?: string = undefined;
	value?: string = undefined;
	count?: string = undefined;
	categoria?: string = undefined;
	subcategoria?: string = undefined;
	marca?: string = undefined;

	constructor(properties?: MatchedRouteKey) {
		// Usa el spread operator para asignar todas las propiedades
		// y el operador ?? para asignar valores por defecto
		Object.assign(this, {
			key: properties?.key ?? undefined,
			value: properties?.value ?? undefined,
			count: properties?.count ?? undefined,
			categoria: properties?.categoria ?? undefined,
			subcategoria: properties?.subcategoria ?? undefined,
			marca: properties?.marca ?? undefined,
		});
	}
}

export class SluggableEntity implements Sluggable {
	id: number = NaN;
	key: string = "";
	value: string = "";
	types?: PersonStrategyKey[] = undefined;

	constructor(properties?: {
		id: string;
		value: string;
		types?: PersonStrategyKey[];
	}) {
		if (properties) {
			this.id = parseInt(properties.id);
			this.key = slugify(properties.value);
			this.value = properties.value;
			this.types = properties.types;
		}
	}
}

export class FetchFilterEntity implements FetchFilter {
	[PersonGeoKey.STATES]: Boolean = false;
	[PersonGeoKey.CITIES]: Boolean = false;
	[PersonGeoKey.NEIGHBORHOODS]: Boolean = false;

	[PersonPoisKey.CATEGORIES]: Boolean = false;
	[PersonPoisKey.SUBCATEGORIES]: Boolean = false;
	[PersonPoisKey.BRANDS]: Boolean = false;
	[PersonPoisKey.NAMES]: Boolean = false;

	[PersonOohKey.OOH_CATEGORIES]: Boolean = false;
	[PersonOohKey.OOH_SUBCATEGORIES]: Boolean = false;
	[PersonOohKey.OOH_BRANDS]: Boolean = false;
	[PersonOohKey.OOH_NAMES]: Boolean = false;

	[PersonPosKey.CHIPPER_STATES]: Boolean = false;
	[PersonPosKey.CHIPPER_CITIES]: Boolean = false;
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: Boolean = false;
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: Boolean = false;
	[PersonPosKey.CHIPPER_CATEGORIES]: Boolean = false;
	[PersonPosKey.CHIPPER_COMPANIES]: Boolean = false;
	[PersonPosKey.CHIPPER_BRANDS]: Boolean = false;
	[PersonPosKey.CHIPPER_NAMES_SKU]: Boolean = false;
	[PersonPosKey.CHIPPER_STORES_TYPE]: Boolean = false;

	[PersonPrivateKey.PRIVATES]: Boolean = false;

	constructor(properties?: FetchFilter) {
		if (properties) {
			this[PersonGeoKey.STATES] = properties[PersonGeoKey.STATES];
			this[PersonGeoKey.CITIES] = properties[PersonGeoKey.CITIES];
			this[PersonGeoKey.NEIGHBORHOODS] =
				properties[PersonGeoKey.NEIGHBORHOODS];

			this[PersonPoisKey.CATEGORIES] =
				properties[PersonPoisKey.CATEGORIES];
			this[PersonPoisKey.SUBCATEGORIES] =
				properties[PersonPoisKey.SUBCATEGORIES];
			this[PersonPoisKey.BRANDS] = properties[PersonPoisKey.BRANDS];
			this[PersonPoisKey.NAMES] = properties[PersonPoisKey.NAMES];

			this[PersonOohKey.OOH_CATEGORIES] =
				properties[PersonOohKey.OOH_CATEGORIES];
			this[PersonOohKey.OOH_SUBCATEGORIES] =
				properties[PersonOohKey.OOH_SUBCATEGORIES];
			this[PersonOohKey.OOH_BRANDS] = properties[PersonOohKey.OOH_BRANDS];
			this[PersonOohKey.OOH_NAMES] = properties[PersonOohKey.OOH_NAMES];

			this[PersonPosKey.CHIPPER_STATES] =
				properties[PersonPosKey.CHIPPER_STATES];
			this[PersonPosKey.CHIPPER_CITIES] =
				properties[PersonPosKey.CHIPPER_CITIES];
			this[PersonPosKey.CHIPPER_NEIGHBORHOODS] =
				properties[PersonPosKey.CHIPPER_NEIGHBORHOODS];
			this[PersonPosKey.CHIPPER_MACRO_CATEGORIES] =
				properties[PersonPosKey.CHIPPER_MACRO_CATEGORIES];
			this[PersonPosKey.CHIPPER_CATEGORIES] =
				properties[PersonPosKey.CHIPPER_CATEGORIES];
			this[PersonPosKey.CHIPPER_COMPANIES] =
				properties[PersonPosKey.CHIPPER_COMPANIES];
			this[PersonPosKey.CHIPPER_BRANDS] =
				properties[PersonPosKey.CHIPPER_BRANDS];
			this[PersonPosKey.CHIPPER_NAMES_SKU] =
				properties[PersonPosKey.CHIPPER_NAMES_SKU];
			this[PersonPosKey.CHIPPER_STORES_TYPE] =
				properties[PersonPosKey.CHIPPER_STORES_TYPE];

			this[PersonPrivateKey.PRIVATES] =
				properties[PersonPrivateKey.PRIVATES];
		}
	}

	setFetching(key: PersonFilterType, status: Boolean) {
		this[key] = status;
	}

	isFetching(key: PersonFilterType): Boolean {
		return this[key];
	}

	setFetchingAll() {
		this[PersonGeoKey.STATES] = true;
		this[PersonGeoKey.CITIES] = true;
		this[PersonGeoKey.NEIGHBORHOODS] = true;

		this[PersonPoisKey.CATEGORIES] = true;
		this[PersonPoisKey.SUBCATEGORIES] = true;
		this[PersonPoisKey.BRANDS] = true;
		this[PersonPoisKey.NAMES] = true;

		this[PersonOohKey.OOH_CATEGORIES] = true;
		this[PersonOohKey.OOH_SUBCATEGORIES] = true;
		this[PersonOohKey.OOH_BRANDS] = true;
		this[PersonOohKey.OOH_NAMES] = true;

		this[PersonPosKey.CHIPPER_STATES] = true;
		this[PersonPosKey.CHIPPER_CITIES] = true;
		this[PersonPosKey.CHIPPER_NEIGHBORHOODS] = true;
		this[PersonPosKey.CHIPPER_MACRO_CATEGORIES] = true;
		this[PersonPosKey.CHIPPER_CATEGORIES] = true;
		this[PersonPosKey.CHIPPER_CATEGORIES] = true;
		this[PersonPosKey.CHIPPER_BRANDS] = true;
		this[PersonPosKey.CHIPPER_NAMES_SKU] = true;
		this[PersonPosKey.CHIPPER_STORES_TYPE] = true;

		this[PersonPrivateKey.PRIVATES] = true;
	}
}

export const MATCHED_KEYS = {
	[PersonKey.POIS]: {
		categories: PersonPoisKey.CATEGORIES,
		subcategories: PersonPoisKey.CATEGORIES,
		brands: PersonPoisKey.CATEGORIES,
		names: PersonPoisKey.CATEGORIES,
	},
	[PersonKey.OOH]: {
		categories: PersonOohKey.OOH_CATEGORIES,
		subcategories: PersonOohKey.OOH_SUBCATEGORIES,
		brands: PersonOohKey.OOH_BRANDS,
		names: PersonOohKey.OOH_NAMES,
	},
};

export const MATCH_USES_CASES = {
	[PersonUsesCasesKey.SEEN_CERTAIN_AREA]: [PersonStrategyKey.CREATE_AUDIENCE],
	[PersonUsesCasesKey.SEEN_CLOSE_TO_POI_OOH]: [
		PersonStrategyKey.CREATE_AUDIENCE,
	],
	[PersonUsesCasesKey.POIS_CLOSE_CERTAIN_OOHS]: [
		PersonStrategyKey.IDENTIFY_POIS_OOH,
	],
	[PersonUsesCasesKey.SEEN_POS_AREA]: [PersonStrategyKey.CREATE_AUDIENCE],
	[PersonUsesCasesKey.STORE_ATTRIBUTION_REPORT]: [
		PersonStrategyKey.CREATE_AUDIENCE,
	],
};

/**
 * Datos estructurado para obtener tabs y botones
 * por medio de la seleccion de strategy y use cases
 */
export const USES_CASES_VISIBILITIES: UseCaseVisibilityEntity[] = [
	/**
	 * NONE
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.NONE,
		uses_cases_type: PersonUsesCasesKey.NONE,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.GEO,
			PersonKey.POIS,
			PersonKey.PRIVATE,
			PersonKey.POS,
		],
		buttons: [
			ButtonActionType.ANALYZE_POIS,
			ButtonActionType.CALCULATE_REACH,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.SAVE_POIS,
			ButtonActionType.TARGET_AUDIENCE,
			ButtonActionType.GEO_FENCING,
			ButtonActionType.GEO_REPORT,
			ButtonActionType.STORE_ATTR,
			ButtonActionType.ACTIVATE_OOH,
		],
	}),

	/**
	 * CREATE_AUDIENCE
	 * SEEN_CERTAIN_AREA
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.CREATE_AUDIENCE,
		uses_cases_type: PersonUsesCasesKey.SEEN_CERTAIN_AREA,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.GEO,
		],
		buttons: [
			ButtonActionType.ANALYZE_GEO,
			ButtonActionType.CALCULATE_REACH,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.TARGET_AUDIENCE,
		],
	}),

	/**
	 * CREATE_AUDIENCE
	 * SEEN_CLOSE_TO_POI_OOH
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.CREATE_AUDIENCE,
		uses_cases_type: PersonUsesCasesKey.SEEN_CLOSE_TO_POI_OOH,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.GEO,
			PersonKey.POIS,
			PersonKey.PRIVATE,
			PersonKey.OOH,
		],
		buttons: [
			ButtonActionType.ANALYZE_POIS,
			ButtonActionType.CALCULATE_REACH,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.SAVE_POIS,
			ButtonActionType.TARGET_AUDIENCE,
			ButtonActionType.ACTIVATE_OOH,
		],
	}),

	/**
	 * CREATE_AUDIENCE
	 * SEEN_POS_AREA
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.CREATE_AUDIENCE,
		uses_cases_type: PersonUsesCasesKey.SEEN_POS_AREA,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.POS,
		],
		buttons: [
			ButtonActionType.ANALYZE_POS,
			ButtonActionType.CALCULATE_REACH,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.TARGET_AUDIENCE,
		],
	}),

	/**
	 * CREATE_AUDIENCE
	 * STORE_ATTRIBUTION_REPORT
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.CREATE_AUDIENCE,
		uses_cases_type: PersonUsesCasesKey.STORE_ATTRIBUTION_REPORT,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.GEO,
			PersonKey.POIS,
			PersonKey.PRIVATE,
			PersonKey.OOH,
		],
		buttons: [
			ButtonActionType.ANALYZE_POIS,
			ButtonActionType.ANALYZE_AUDIENCE,
			ButtonActionType.SAVE_POIS,
			ButtonActionType.STORE_ATTR,
			ButtonActionType.TARGET_AUDIENCE,
		],
	}),

	/**
	 * IDENTIFY_POIS_OOH
	 * POIS_CLOSE_CERTAIN_OOHS
	 */
	new UseCaseVisibilityEntity({
		strategy_type: PersonStrategyKey.IDENTIFY_POIS_OOH,
		uses_cases_type: PersonUsesCasesKey.POIS_CLOSE_CERTAIN_OOHS,
		tabs: [
			PersonKey.COUNTRY_GLOBAL,
			PersonKey.STRATEGY,
			PersonKey.USES_CASES,
			PersonKey.GEO,
			PersonKey.POIS,
			PersonKey.PRIVATE,
			PersonKey.OOH,
		],
		buttons: [
			ButtonActionType.ANALYZE_POIS,
			ButtonActionType.GEO_FENCING,
			ButtonActionType.SAVE_POIS,
		],
	}),
];

/**
 * Buscar en USES_CASES_VISIBILITIES
 * por medio de strategy y uses cases
 * @param strategyType
 * @param useCasesType
 * @returns
 */
export function searchVisibility(
	strategyType: string,
	useCasesType?: string
): UseCaseVisibilityEntity | undefined {
	return USES_CASES_VISIBILITIES.find(
		(strategy) =>
			matchesStrategyType(strategy, strategyType) &&
			matchesUseCasesType(strategy, useCasesType)
	);
}

function matchesStrategyType(
	{ strategy_type }: UseCaseVisibilityEntity,
	strategyType: string
): boolean {
	return strategy_type === strategyType;
}

function matchesUseCasesType(
	{ uses_cases_type }: UseCaseVisibilityEntity,
	useCasesType?: string
): boolean {
	return !useCasesType || uses_cases_type === useCasesType;
}

export const TYPE_FILTER = {
	[PersonKey.GEO]: [
		PersonGeoKey.STATES,
		PersonGeoKey.CITIES,
		PersonGeoKey.NEIGHBORHOODS,
	],
	[PersonKey.POIS]: [
		PersonPoisKey.CATEGORIES,
		PersonPoisKey.SUBCATEGORIES,
		PersonPoisKey.BRANDS,
		PersonPoisKey.NAMES,
	],
	[PersonKey.PRIVATE]: [PersonPrivateKey.PRIVATES],
	[PersonKey.OOH]: [
		PersonOohKey.OOH_CATEGORIES,
		PersonOohKey.OOH_SUBCATEGORIES,
		PersonOohKey.OOH_BRANDS,
		PersonOohKey.OOH_NAMES,
	],
	[PersonKey.POS]: [
		PersonPosKey.CHIPPER_STATES,
		PersonPosKey.CHIPPER_CITIES,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_MACRO_CATEGORIES,
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
};

export const REQUIRED_SECTIONS = [
	PersonKey.COUNTRY_GLOBAL,
	PersonKey.STRATEGY,
	PersonKey.USES_CASES,
];

export const FILTER_SECTIONS = [
	PersonKey.GEO,
	PersonKey.POIS,
	PersonKey.POS,
	PersonKey.OOH,
	PersonKey.PRIVATE,
];

export const FILTER_KEY_MAPPING: Record<PersonKey, PersonFilterType[]> = {
	[PersonKey.GEO]: [
		PersonGeoKey.STATES,
		PersonGeoKey.CITIES,
		PersonGeoKey.NEIGHBORHOODS,
	],
	[PersonKey.POIS]: [
		PersonPoisKey.CATEGORIES,
		PersonPoisKey.SUBCATEGORIES,
		PersonPoisKey.BRANDS,
		PersonPoisKey.NAMES,
	],
	[PersonKey.OOH]: [
		PersonOohKey.OOH_CATEGORIES,
		PersonOohKey.OOH_SUBCATEGORIES,
		PersonOohKey.OOH_BRANDS,
		PersonOohKey.OOH_NAMES,
	],
	[PersonKey.POS]: [
		PersonPosKey.CHIPPER_STATES,
		PersonPosKey.CHIPPER_CITIES,
		PersonPosKey.CHIPPER_NEIGHBORHOODS,
		PersonPosKey.CHIPPER_MACRO_CATEGORIES,
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
		PersonPosKey.CHIPPER_QUINTILS,
	],
	[PersonKey.PRIVATE]: [PersonPrivateKey.PRIVATES],
	[PersonKey.NONE]: [],
	[PersonKey.COUNTRY_GLOBAL]: [],
	[PersonKey.AUDIENTE]: [],
	[PersonKey.STORE_ATTRIBUTION]: [],
	[PersonKey.STRATEGY]: [],
	[PersonKey.USES_CASES]: [],
};

export function determineFilterKey(key: any): PersonKey {
	if (FILTER_KEY_MAPPING[PersonKey.GEO].includes(key)) return PersonKey.GEO;
	if (FILTER_KEY_MAPPING[PersonKey.POIS].includes(key)) return PersonKey.POIS;
	if (FILTER_KEY_MAPPING[PersonKey.OOH].includes(key)) return PersonKey.OOH;
	if (FILTER_KEY_MAPPING[PersonKey.POS].includes(key)) return PersonKey.POS;
	if (FILTER_KEY_MAPPING[PersonKey.PRIVATE].includes(key))
		return PersonKey.PRIVATE;
	return PersonKey.NONE;
}

export function determineIfSetDefaultValue(type: PersonFilterType): Boolean {
	const filterType: PersonKey = determineFilterKey(type);
	return [
		PersonKey.GEO,
		PersonKey.POIS,
		PersonKey.OOH,
		PersonKey.POS,
	].includes(filterType);
}

export function getFilterSlice(personKey: PersonKey, type: PersonFilterType) {
	const mapping = FILTER_KEY_MAPPING[personKey];

	if ([PersonKey.POS].includes(personKey)) {
		// Filtrar los valores del objeto para eliminar el type
		const filteredValues = Object.values(mapping).filter(
			(value) => value !== type
		);
		return filteredValues;
	}

	return mapping.slice(FilterKeySlice[type]);
}
