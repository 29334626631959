import { isEmpty, isNull, isNumber, isUndefined } from "lodash";
import {
	ElementData,
	Person,
	GeoFilterData,
	PoisFilterData,
	Audience,
	ActionLoading,
	ActionResponse,
	ElementDataWithType,
	PrivateFilterData,
	OohFilterData,
	PosFilterData,
} from "@/interfaces/persons/v10/person";
import {
	PersonKeyof,
	PersonKey,
	GeoFilterDataKeyof,
	GeoKeyof,
	TypeFilterKey,
	PersonGeoKey,
	PersonPoisKey,
	ActivePanelTab,
	MatchFilter,
	PersonStorageKey,
	PersonUsesCasesKey,
	PersonStrategyKey,
	PersonOohKey,
	ButtonActionType,
	PersonFilterType,
	PersonPosKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";
import {
	SelectedDataEntity,
	GeoFilterDataEntity,
	PoisFilterDataEntity,
	PersonAudienceEntity,
	PersonStoreAttributionEntity,
	ActionLoadingEntity,
	ActionResponseEntity,
	GraphicResponseEntity,
	PersonTargetAudienceEntity,
	PersonSavePoisEntity,
	PersonGeoFencingEntity,
	PrivateFilterDataEntity,
	UseCaseVisibilityEntity,
	searchVisibility,
	OohFilterDataEntity,
	ActivePanelNextEntity,
	FetchFilterEntity,
	FILTER_KEY_MAPPING,
	FILTER_SECTIONS,
	PersonActivateOOHEntity,
	PosFilterDataEntity,
} from "./Implements";
import {
	ConfigPost,
	ConfigPostFilter,
} from "@/interfaces/persons/v10/response";
import { ConfigPostEntity } from "./response";
import {
	AssignID,
	AssignIDs,
	LikeName,
} from "@/interfaces/persons/v10/select_like";
import { Mode } from "@/interfaces/persons/v10/query/global";
import { PostDataEntity } from "./Query/Pois";
import {
	ModuleSelectAllEntity,
	SelectAllGeoEntity,
	SelectAllOhhEntity,
	SelectAllPoisEntity,
	SelectAllPosEntity,
	SelectAllPrivateEntity,
	TotalTypeAllEntity,
} from "./SelectAll/Class";
import { TotalTypeAll } from "@/interfaces/persons/v10/select_all";
import i18n from "@/plugins/i18n";
import { getFromStorage } from "@/services/storage-service";
import { isBackFromAudience } from "@/utils/services-global";
import { TabSelectionOptional } from "@/interfaces/persons/v10/tabs/pois";
import {
	AUDIENCE_TYPE_GEO,
	AUDIENCE_TYPE_POIS,
	AUDIENCE_TYPE_POS,
	COUNTRY_CODE_NAMES,
	COUNTRY_DEFAULT,
	ENDPOINT_KEYS,
	HIDE_VIEW_CLEAR_FILTERS,
} from "./DataDefault";

export class PersonEntity implements Person {
	country_global: ElementData = new SelectedDataEntity({
		id: COUNTRY_DEFAULT.id,
		value: COUNTRY_DEFAULT.value,
	});
	strategy: ElementData = new SelectedDataEntity();
	uses_cases: ElementData = new SelectedDataEntity(); // old: audience_type
	geo: GeoFilterData = new GeoFilterDataEntity();
	pois: PoisFilterData = new PoisFilterDataEntity();
	privates: PrivateFilterData = new PrivateFilterDataEntity();
	ooh: OohFilterData = new OohFilterDataEntity();
	pos: PosFilterData = new PosFilterDataEntity();
	store_attribution: PersonStoreAttributionEntity =
		new PersonStoreAttributionEntity();
	target_audience = new PersonTargetAudienceEntity();
	save_pois = new PersonSavePoisEntity();
	geo_fencing = new PersonGeoFencingEntity();
	activate_ooh = new PersonActivateOOHEntity();
	audience: Audience = new PersonAudienceEntity();
	table_id: string | null = null;

	/**
	 * ButtonAction
	 * Loading & Response
	 */
	loading: ActionLoading = new ActionLoadingEntity();
	response: ActionResponse = new ActionResponseEntity();

	/**
	 * Graphics
	 * pois & audience
	 * Loading & ItemGraphic[]
	 *
	 */
	graphics: GraphicResponseEntity = new GraphicResponseEntity();

	// Select All Data
	select_all: ModuleSelectAllEntity = new ModuleSelectAllEntity();

	// Visibility: tabs & buttons
	visibility: UseCaseVisibilityEntity = new UseCaseVisibilityEntity();

	fetching_filter: FetchFilterEntity = new FetchFilterEntity();

	synchronizing_filters: boolean = false;


	createPerson(properties: PersonEntity) {
		this.country_global = new SelectedDataEntity({
			id: properties.country_global.id,
			value: properties.country_global.value,
		});
		this.strategy = new SelectedDataEntity(properties.strategy);
		this.uses_cases = new SelectedDataEntity(properties.uses_cases);
		this.geo = new GeoFilterDataEntity(properties.geo);
		this.pois = new PoisFilterDataEntity(properties.pois);
		this.privates = new PrivateFilterDataEntity(properties.privates);
		this.ooh = new OohFilterDataEntity(properties.ooh);
		this.pos = new PosFilterDataEntity(properties.pos);
		this.store_attribution = new PersonStoreAttributionEntity(
			properties.store_attribution
		);
		this.target_audience = new PersonTargetAudienceEntity(
			properties.target_audience
		);
		this.save_pois = new PersonSavePoisEntity(properties.save_pois);
		this.geo_fencing = new PersonGeoFencingEntity(properties.geo_fencing);
		this.activate_ooh = new PersonActivateOOHEntity(
			properties.activate_ooh
		);
		this.table_id = properties.table_id;
		this.loading = new ActionLoadingEntity();
		this.response = new ActionResponseEntity(properties.response);
		this.graphics = new GraphicResponseEntity(properties.graphics);
		this.select_all = new ModuleSelectAllEntity(properties.select_all);
		this.visibility = new UseCaseVisibilityEntity(properties.visibility);
		this.fetching_filter = new FetchFilterEntity(
			properties.fetching_filter
		);

		return {
			country_global: this.country_global,
			strategy: this.strategy,
			uses_cases: this.uses_cases,
			geo: this.geo,
			pois: this.pois,
			ooh: this.ooh,
			pos: this.pos,
			privates: this.privates,
			store_attribution: this.store_attribution,
			save_pois: this.save_pois,
			geo_fencing: this.geo_fencing,
			activate_ooh: this.activate_ooh,
			table_id: this.table_id,
			loading: this.loading,
			response: this.response,
			graphics: this.graphics,
			select_all: this.select_all,
			visibility: this.visibility,
			fetching_filter: this.fetching_filter,
		} as PersonEntity;
	}

	constructor(country?: ElementData) {
		const isBack: Boolean = isBackFromAudience();

		if (country) {
			this.country_global = new SelectedDataEntity({
				id: country.id,
				value: country.value,
			});
		}

		if (isBack) {
			const person: PersonEntity | undefined = this.getDataFromStorage();
			if (!person) return;
			this.createPerson(person);
		}
	}

	getDataFromStorage(): PersonEntity | undefined {
		const storedPerson = getFromStorage(PersonStorageKey.STORED_PERSON);
		return storedPerson ? JSON.parse(storedPerson) : undefined;
	}

	// Getters

	getCountry() {
		return this.country_global;
	}

	getByKey(key: PersonKeyof) {
		return this[key];
	}

	getCountryCode(
		attribute: "id" | "value" = "id",
		type?: "string" | "array"
	) {
		let countryGlobal: ElementData = this.country_global;

		let countries: ElementData[] = this.hasCountry() ? [countryGlobal] : [];

		if (type === "string") {
			return countryGlobal[attribute];
		}

		if (type === "array") {
			return countries.map((c) => {
				if (!isNumber(c[attribute])) {
					return String(c[attribute]).toUpperCase();
				}
				return c[attribute];
			});
		}

		return countryGlobal;
	}

	getSelectedByType(type: PersonKeyof) {
		if (type == PersonKey.COUNTRY_GLOBAL) {
			return this.hasCountry() ? [this.country_global] : [];
		}
		if (type == PersonKey.STRATEGY) {
			return this.hasStrategy() ? [this.strategy] : [];
		}
		if (type == PersonKey.GEO) {
			return [
				...this.geo.selected[PersonGeoKey.STATES],
				...this.geo.selected[PersonGeoKey.CITIES],
				...this.geo.selected[PersonGeoKey.NEIGHBORHOODS],
			];
		}
		if (type == PersonKey.POIS) {
			return [
				...this.pois.selected[PersonPoisKey.CATEGORIES],
				...this.pois.selected[PersonPoisKey.SUBCATEGORIES],
				...this.pois.selected[PersonPoisKey.BRANDS],
				...this.pois.selected[PersonPoisKey.NAMES],
			];
		}
		if (type == PersonKey.PRIVATE) {
			return [...this.privates.selected[PersonPrivateKey.PRIVATES]];
		}
		if (type == PersonKey.OOH) {
			return [
				...this.ooh.selected[PersonOohKey.OOH_CATEGORIES],
				...this.ooh.selected[PersonOohKey.OOH_SUBCATEGORIES],
				...this.ooh.selected[PersonOohKey.OOH_BRANDS],
				...this.ooh.selected[PersonOohKey.OOH_NAMES],
			];
		}
		if (type == PersonKey.POS) {
			return [
				...this.geo.selected[PersonPosKey.CHIPPER_STATES],
				...this.geo.selected[PersonPosKey.CHIPPER_CITIES],
				...this.geo.selected[PersonPosKey.CHIPPER_NEIGHBORHOODS],
				...this.pos.selected[PersonPosKey.CHIPPER_MACRO_CATEGORIES],
				...this.pos.selected[PersonPosKey.CHIPPER_CATEGORIES],
				...this.pos.selected[PersonPosKey.CHIPPER_COMPANIES],
				...this.pos.selected[PersonPosKey.CHIPPER_BRANDS],
				...this.pos.selected[PersonPosKey.CHIPPER_NAMES_SKU],
				...this.pos.selected[PersonPosKey.CHIPPER_STORES_TYPE],
				...this.pos.selected[PersonPosKey.CHIPPER_QUINTILS],
			];
		}
		return [];
	}

	addToolbar(type: TypeFilterKey) {
		let filters = [] as any[];

		const filterSelecteds = this.geo[type];

		for (const [key, value] of Object.entries(filterSelecteds)) {
			for (const element of value) {
				filters.push({
					type: type,
					key: key,
					id: element.id,
					value: element.value,
					tooltip: `${element.value} (${key.toUpperCase()})`,
				});
			}
		}

		return {
			title: type === TypeFilterKey.SELECTED ? "Geography" : "And",
			filters: filters.flat(),
		};
	}

	getGeoToolbars() {
		let toolbars = [this.addToolbar(TypeFilterKey.SELECTED)];
		return toolbars;
	}

	getSelectedFilters(type: PersonKeyof) {
		let filters: ElementData[] = [];

		if (type == PersonKey.COUNTRY_GLOBAL) {
			return this.hasCountry()
				? [{ ...this.country_global, type: PersonKey.COUNTRY_GLOBAL }]
				: [];
		}
		if (type == PersonKey.STRATEGY) {
			return !isNaN(this.strategy?.id)
				? [{ ...this.strategy, type: PersonKey.STRATEGY }]
				: [];
		}
		if (type == PersonKey.USES_CASES) {
			return !isNaN(this.uses_cases?.id)
				? [{ ...this.uses_cases, type: PersonKey.USES_CASES }]
				: [];
		}

		if (type == PersonKey.GEO) {
			return [
				...this.addTypeToElement(
					this.geo.selected.states,
					LikeName.STATES
				),
				...this.addTypeToElement(
					this.geo.selected.cities,
					LikeName.CITIES
				),
				...this.addTypeToElement(
					this.prepareChippedFilters(
						PersonKey.GEO,
						PersonGeoKey.NEIGHBORHOODS
					),
					LikeName.NEIGHBORHOODS
				),
			];
		}

		if (type == PersonKey.POIS) {
			return [
				...this.addTypeToElement(
					this.pois.selected.categories,
					LikeName.CATEGORIES
				),
				...this.addTypeToElement(
					this.pois.selected.subcategories,
					LikeName.SUBCATEGORIES
				),
				...this.addTypeToElement(
					this.pois.selected.brands,
					LikeName.BRANDS
				),
				...this.addTypeToElement(
					this.prepareChippedFilters(
						PersonKey.POIS,
						PersonPoisKey.NAMES
					),
					LikeName.NAMES
				),
			];
		}

		if (type == PersonKey.PRIVATE) {
			return [
				...this.addTypeToElement(
					this.privates.selected.privates,
					LikeName.PRIVATES
				),
			];
		}

		if (type == PersonKey.OOH) {
			return [
				...this.addTypeToElement(
					this.ooh.selected.ooh_categories,
					LikeName.CATEGORIES
				),
				...this.addTypeToElement(
					this.ooh.selected.ooh_subcategories,
					LikeName.SUBCATEGORIES
				),
				...this.addTypeToElement(
					this.ooh.selected.ooh_brands,
					LikeName.BRANDS
				),
				...this.addTypeToElement(
					this.prepareChippedFilters(
						PersonKey.OOH,
						PersonOohKey.OOH_NAMES
					),
					LikeName.NAMES
				),
			];
		}

		if (type == PersonKey.POS) {
			return [
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_STATES],
					LikeName.CHIPPER_STATES
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_CITIES],
					LikeName.CHIPPER_CITIES
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_NEIGHBORHOODS],
					LikeName.CHIPPER_NEIGHBORHOODS
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_MACRO_CATEGORIES],
					LikeName.CHIPPER_MACRO_CATEGORIES
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_CATEGORIES],
					LikeName.CHIPPER_CATEGORIES
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_COMPANIES],
					LikeName.CHIPPER_COMPANIES
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_BRANDS],
					LikeName.CHIPPER_BRANDS
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_NAMES_SKU],
					LikeName.CHIPPER_NAMES_SKU
				),
				...this.addTypeToElement(
					this.pos.selected[PersonPosKey.CHIPPER_STORES_TYPE],
					LikeName.CHIPPER_STORES_TYPE
				),
			];
		}

		return filters;
	}

	addTypeToElement(
		items: ElementData[],
		type: LikeName
	): ElementDataWithType[] {
		return (
			items?.map((item) => ({
				...item,
				type,
			})) || []
		);
	}

	prepareChippedFilters(
		type: PersonKey,
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity
	) {
		let elements: ElementData[] = this[type].selected[name];
		let like: TotalTypeAll = this.select_all[type][name];
		if (!isEmpty(elements) && like.total.checked && like.total.term) {
			elements = [
				{
					id: AssignID[name],
					value: i18n
						.t("select_all.like.names", {
							term: like.total.term,
						})
						.toString(),
				},
			];
		}

		if (isEmpty(elements)) {
			like = new TotalTypeAllEntity();
		}

		return elements;
	}

	getCountryArray() {
		if (!this.hasCountry()) return [];
		return [this.country_global.value];
	}

	getAudienceTypeArray() {
		if (!this.hasStrategy()) return [];
		return [this.strategy.value];
	}

	getParamsStates() {
		return this.geo.pre.states.map((s) => s.id);
	}

	getParamsCities() {
		return this.geo.pre.cities.map((s) => s.id);
	}

	getEntityTypes(filterType: TypeFilterKey) {
		return {
			geo: {
				states: this.geo[filterType].states.map((f) => f.value),
				cities: this.geo[filterType].cities.map((f) => f.value),
				neighborhoods: this.geo[
					TypeFilterKey.SELECTED
				].neighborhoods.map((f) => f.value),
			},
			pos: {
				states: this.pos[filterType].chipper_states.map((f) => f.value),
				cities: this.pos[filterType].chipper_cities.map((f) => f.value),
				neighborhoods: this.pos[
					TypeFilterKey.SELECTED
				].chipper_neighborhoods.map((f) => f.value),
			},
		};
	}

	getSelectAllTypes() {
		return {
			geo: {
				states: this.select_all.geo.states,
				cities: this.select_all.geo.cities,
				neighborhoods: this.select_all.geo.neighborhoods,
			},
			pos: {
				states: this.select_all.pos.chipper_states,
				cities: this.select_all.pos.chipper_cities,
				neighborhoods: this.select_all.pos.chipper_neighborhoods,
			},
		};
	}

	async addFilterToGeo(config: ConfigPostEntity, filterType: TypeFilterKey) {
		const isPos = this.isPosArea();
		const entityTypes = this.getEntityTypes(filterType);
		const checkSelectAllTypes = this.getSelectAllTypes();

		const CHECK = isPos ? checkSelectAllTypes.pos : checkSelectAllTypes.geo;
		const ENTITY = isPos ? entityTypes.pos : entityTypes.geo;

		config.addGeoFilter(PersonGeoKey.STATES, CHECK.states, ENTITY.states);

		config.addGeoFilter(PersonGeoKey.CITIES, CHECK.cities, ENTITY.cities);

		config.addGeoFilter(
			PersonGeoKey.NEIGHBORHOODS,
			CHECK.neighborhoods,
			ENTITY.neighborhoods
		);

		return config;
	}

	async addFilterToPois(config: ConfigPostEntity) {
		const check = {
			categories: this.select_all.pois.categories,
			subcategories: this.select_all.pois.subcategories,
			brands: this.select_all.pois.brands,
			names: this.select_all.pois.names,
			privates: this.select_all.privates.privates,
		};

		config.addPoisFilter(
			PersonPoisKey.CATEGORIES,
			check.categories,
			this.removeDuplicatesByIdentifier(
				this.pois.selected.categories
			).map((s) => s.value)
		);
		config.addPoisFilter(
			PersonPoisKey.SUBCATEGORIES,
			check.subcategories,
			this.removeDuplicatesByIdentifier(
				this.pois.selected.subcategories
			).map((s) => s.value)
		);
		config.addPoisFilter(
			PersonPoisKey.BRANDS,
			check.brands,
			this.removeDuplicatesByIdentifier(this.pois.selected.brands).map(
				(s) => s.value
			)
		);
		config.addPoisFilter(
			PersonPoisKey.NAMES,
			check.names,
			this.removeDuplicatesByIdentifier(this.pois.selected.names).map(
				(s) => s.value
			)
		);

		return config;
	}

	async addFilterToOoh(config: ConfigPostEntity) {
		const check = {
			categories: this.select_all.ooh.ooh_categories,
			subcategories: this.select_all.ooh.ooh_subcategories,
			brands: this.select_all.ooh.ooh_brands,
			names: this.select_all.ooh.ooh_names,
		};

		config.addOohFilter(
			PersonOohKey.OOH_CATEGORIES,
			check.categories,
			this.removeDuplicatesByIdentifier(
				this.ooh.selected.ooh_categories
			).map((s) => s.value)
		);
		config.addOohFilter(
			PersonOohKey.OOH_SUBCATEGORIES,
			check.subcategories,
			this.removeDuplicatesByIdentifier(
				this.ooh.selected.ooh_subcategories
			).map((s) => s.value)
		);
		config.addOohFilter(
			PersonOohKey.OOH_BRANDS,
			check.brands,
			this.removeDuplicatesByIdentifier(this.ooh.selected.ooh_brands).map(
				(s) => s.value
			)
		);
		config.addOohFilter(
			PersonOohKey.OOH_NAMES,
			check.names,
			this.removeDuplicatesByIdentifier(this.ooh.selected.ooh_names).map(
				(s) => s.value
			)
		);

		return config;
	}

	async addFilterToPos(config: ConfigPostEntity) {
		const check = {
			[PersonPosKey.CHIPPER_MACRO_CATEGORIES]:
				this.select_all.pos[PersonPosKey.CHIPPER_MACRO_CATEGORIES],
			[PersonPosKey.CHIPPER_CATEGORIES]:
				this.select_all.pos[PersonPosKey.CHIPPER_CATEGORIES],
			[PersonPosKey.CHIPPER_COMPANIES]:
				this.select_all.pos[PersonPosKey.CHIPPER_COMPANIES],
			[PersonPosKey.CHIPPER_BRANDS]:
				this.select_all.pos[PersonPosKey.CHIPPER_BRANDS],
			[PersonPosKey.CHIPPER_NAMES_SKU]:
				this.select_all.pos[PersonPosKey.CHIPPER_NAMES_SKU],
			[PersonPosKey.CHIPPER_STORES_TYPE]:
				this.select_all.pos[PersonPosKey.CHIPPER_STORES_TYPE],
		};

		config.addPosQuintils(
			this.pos.selected[PersonPosKey.CHIPPER_QUINTILS].map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_MACRO_CATEGORIES,
			check[PersonPosKey.CHIPPER_MACRO_CATEGORIES],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_MACRO_CATEGORIES]
			).map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_CATEGORIES,
			check[PersonPosKey.CHIPPER_CATEGORIES],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_CATEGORIES]
			).map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_COMPANIES,
			check[PersonPosKey.CHIPPER_COMPANIES],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_COMPANIES]
			).map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_BRANDS,
			check[PersonPosKey.CHIPPER_BRANDS],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_BRANDS]
			).map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_NAMES_SKU,
			check[PersonPosKey.CHIPPER_NAMES_SKU],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_NAMES_SKU]
			).map((s) => s.value)
		);

		config.addPosFilter(
			PersonPosKey.CHIPPER_STORES_TYPE,
			check[PersonPosKey.CHIPPER_STORES_TYPE],
			this.removeDuplicatesByIdentifier(
				this.pos.selected[PersonPosKey.CHIPPER_STORES_TYPE]
			).map((s) => s.value)
		);

		return config;
	}

	/*************************
	 * ******CONFIG POST******
	 ************************/

	/**
	 * Get Body: Analize Pois
	 * @returns
	 */
	async getBodyAnalizePois(): Promise<ConfigPost> {
		// Instancia de config
		let config: ConfigPostEntity = await this.getBodyPois();

		// add filters pois
		config = await this.addFilterToPois(config);

		// add filters ooh
		config = await this.addFilterToOoh(config);

		// add filters pos
		config = await this.addFilterToPos(config);

		return config;
	}

	/**
	 * Instancia de config
	 * incluye: country_code, audience_type
	 * @returns
	 */
	async getBodyPois(): Promise<ConfigPostEntity> {
		// init config geo filters
		let config: ConfigPostEntity = new ConfigPostEntity({
			country_code: this.country_global.id,
			strategy: this.strategy.id,
			use_case: this.uses_cases.id,
		});

		return config;
	}

	/**
	 * Instancia inicial de la configuracion de parametros para envio via POST
	 * con datos seleccionados de GEO
	 * incluye: country_code, strategy, audience_type
	 * @returns
	 */
	async getConfigGeo(
		type: TypeFilterKey = TypeFilterKey.PRE
	): Promise<ConfigPostEntity> {
		// Instancia de config
		let config: ConfigPostEntity = await this.getBodyPois();

		// se agregan los filtros seleccionados de GEO: [type]
		config = await this.addFilterToGeo(config, type);

		if (this.hasElementData(PersonKey.GEO, TypeFilterKey.AND)) {
			// se agregan los filtros seleccionados de GEO: AND (and (conditional))
			config = await this.addFilterToGeo(config, TypeFilterKey.AND);
		}

		return config;
	}

	/**
	 * Obtener los parametros para envio via POST
	 * incluye: country_code, strategy, audience_type y layers
	 * @returns
	 */
	async getPostBody(
		type: TypeFilterKey = TypeFilterKey.PRE
	): Promise<ConfigPostEntity> {
		let config: ConfigPostEntity = await this.getConfigGeo(type);

		// Se agregan las capas del filtro Privates
		config.addLayers(
			this.privates[TypeFilterKey.SELECTED].privates.map((s) => s.id)
		);

		return config;
	}

	/**
	 * Remove duplicates by identifier
	 * @param elements
	 * @returns
	 */
	removeDuplicatesByIdentifier(elements: ElementData[]) {
		const seenIdentifiers = new Set<string>();
		return elements.filter((item) => {
			if (item.identifier && !seenIdentifiers.has(item.identifier)) {
				seenIdentifiers.add(item.identifier);
				return true;
			}
			return false;
		});
	}

	/**
	 * Get Body Post: Geo|Pois
	 * @returns
	 */
	async getPostBodyForAnalize(): Promise<ConfigPostEntity> {
		// Instancia de config
		let config: ConfigPostEntity = await this.getConfigGeo();

		config.addLayers(
			this.privates[TypeFilterKey.SELECTED].privates.map((s) => s.id)
		);

		config = await this.addFilterToPois(config);

		config = await this.addFilterToOoh(config);

		config = await this.addFilterToPos(config);

		// Se sobreescribe filters para eliminar los filtros que no tengan valor
		const payload = config.filters.getPayload();
		config.filters = payload as ConfigPostFilter;

		return config;
	}

	/**
	 * Payload de los filtros seleccionados
	 * para Activate OOH
	 * @returns
	 */
	async getActivateOOHPayload() {
		let payload: Record<string, any[]> = {};

		/**
		 * GEO
		 */
		if (this.hasGeoSelected()) {
			let geo: Record<string, any[]> = {};

			if (this.hasStates()) {
				geo["estados"] = this.geo.selected.states.map((s) => s.value);
			}

			if (this.hasCities()) {
				geo["municipios"] = this.geo.selected.cities.map(
					(s) => s.value
				);
			}

			if (this.hasNeighborhoods()) {
				geo["barrios"] = this.geo.selected.neighborhoods.map(
					(s) => s.value
				);
			}

			Object.assign(payload, { geo });
		}

		/**
		 * POIS
		 */
		if (this.hasPoisSelected()) {
			let pois: Record<string, any[]> = {};

			if (this.hasCategories()) {
				pois["categoria"] = this.pois.selected.categories.map(
					(s) => s.value
				);
			}

			if (this.hasSubcategories()) {
				pois["subcategoria"] = this.pois.selected.subcategories.map(
					(s) => s.value
				);
			}

			if (this.hasBrands()) {
				pois["brand"] = this.pois.selected.brands.map((s) => s.value);
			}

			if (this.hasNames()) {
				pois["name"] = this.pois.selected.names.map((s) => s.value);
			}

			Object.assign(payload, { pois });
		}

		/**
		 * OOH
		 */
		if (this.hasOohSelected()) {
			let ooh: Record<string, any[]> = {};

			if (this.hasOOHCategories()) {
				ooh["categoria"] = this.ooh.selected.ooh_categories.map(
					(s) => s.value
				);
			}

			if (this.hasOOHSubCategories()) {
				ooh["subcategoria"] = this.ooh.selected.ooh_subcategories.map(
					(s) => s.value
				);
			}

			if (this.hasOOHBrands()) {
				ooh["brand"] = this.ooh.selected.ooh_brands.map((s) => s.value);
			}

			if (this.hasOOHNames()) {
				ooh["name"] = this.ooh.selected.ooh_names.map((s) => s.value);
			}

			Object.assign(payload, { ooh });
		}

		/**
		 * PRIVATE POIS
		 */
		if (this.hasPoisSelected()) {
			let private_pois: Record<string, any[]> = {};

			if (this.hasPrivateSelected()) {
				private_pois["name"] = this.privates.selected.privates.map(
					(s) => s.value
				);
			}

			Object.assign(payload, { private_pois });
		}

		return { filters: payload };
	}

	async addGeoFilters(config: ConfigPostEntity) {
		config.filters.codigo_estado = this.removeDuplicatesByIdentifier(
			this.geo.pre.states
		).map((s) => s.value);
		config.filters.codigo_ciudad = this.removeDuplicatesByIdentifier(
			this.geo.pre.cities
		).map((s) => s.value);
		config.filters.codigo_barrio = this.removeDuplicatesByIdentifier(
			this.geo.pre.neighborhoods
		).map((s) => s.value);
		return config;
	}

	async setPoisFilters(config: ConfigPostEntity) {
		return await this.setConfigPreFiltersByType(
			PersonKey.POIS,
			TypeFilterKey.PRE,
			config
		);
	}

	async setOohFilters(config: ConfigPostEntity) {
		config = await this.setConfigPreFiltersByType(
			PersonKey.OOH,
			TypeFilterKey.PRE,
			config
		);
		return config;
	}

	async setPosFilters(config: ConfigPostEntity) {
		config = await this.setConfigPreFiltersByType(
			PersonKey.POS,
			TypeFilterKey.PRE,
			config
		);
		return config;
	}

	/**
	 * setConfigPreFiltersByType
	 * @param personKey
	 * @param type
	 * @param config
	 * @returns
	 */
	async setConfigPreFiltersByType(
		personKey: PersonKey,
		type: TypeFilterKey,
		config: ConfigPostEntity
	) {
		const filterKeys = this.getFilterKeysByType(personKey);

		for (const filterKey of filterKeys) {
			/**
			 * Omitir los filtros en el payload [omitTypes]
			 */
			const omitTypes: PersonFilterType[] = [
				PersonPosKey.CHIPPER_STATES,
				PersonPosKey.CHIPPER_CITIES,
				PersonPosKey.CHIPPER_NEIGHBORHOODS,
			];
			if (!omitTypes.includes(filterKey)) {
				config.filters[filterKey] = this.removeDuplicatesByIdentifier(
					this[personKey][type][filterKey] || []
				).map((item) => item.value) || [];
			}
		}

		return config;
	}

	/**
	 * getFilterKeysByType
	 * @param personKey
	 * @returns
	 */
	private getFilterKeysByType(personKey: PersonKey) {
		const filtersByFilterType = FILTER_KEY_MAPPING[personKey];
		return filtersByFilterType;
	}

	/**
	 * Para Post de los filtros Pois y OOHs
	 * Post Params Paginated
	 * @param posData
	 * @param poisType
	 * @param mode
	 * @returns
	 */
	async getPostParamByFilter(
		posData: PostDataEntity,
		poisType: PersonFilterType,
		mode?: Mode,
		type?: TypeFilterKey
	): Promise<ConfigPost> {
		let paginateData = posData.paginateData[poisType];

		if (mode) {
			await paginateData.setMode(mode);
		}

		// Instancia de config con GEO & PRIVATES
		let config: ConfigPostEntity = await this.getPostBody(type);

		const isPois: Boolean = Object.values(PersonPoisKey).includes(
			poisType as PersonPoisKey
		);

		const isOoh: Boolean = Object.values(PersonOohKey).includes(
			poisType as PersonOohKey
		);

		const isPos: Boolean = Object.values(PersonPosKey).includes(
			poisType as PersonPosKey
		);

		if (isPois) {
			config = await this.setPoisFilters(config);
		}

		if (isOoh) {
			config = await this.setOohFilters(config);
		}

		if (isPos) {
			config = await this.setPosFilters(config);
		}

		let configPaginateData = {
			...paginateData.getQuery(),
			...config,
		};

		return configPaginateData;
	}

	getFiltersForCarto() {
		return {
			_country: this.getCountryCode("id", "array"),
			_poisCategory: this.pois.selected.categories,
			_poisSubCategory: this.pois.selected.subcategories,
			_poisMarca: this.pois.selected.brands,
			_poisName: this.pois.selected.names,
			_state: this.geo.selected.states,
			_municipalities: this.geo.selected.cities,
			_neighborhood: this.geo.selected.neighborhoods,
		};
	}

	getConfigModeAll() {
		return {
			country_code: this.country_global.id,
			mode: Mode.ALL,
		};
	}

	getEndpointKey() {
		const country_code = this.country_global?.id;
		const country_name = COUNTRY_CODE_NAMES[country_code] || "WORLD";

		return ENDPOINT_KEYS[country_name];
	}

	getEndpoint() {
		return process.env.VUE_APP_CARTO_URL;
		// return `${process.env.VUE_APP_CARTO_URL}/${this.getEndpointKey()}`;
	}

	// Setters

	setCountry(country: ElementData) {
		this.country_global = country;
	}

	setByKey(key: PersonKeyof, value: any) {
		this[key] = value;
	}

	setTableId(id: string | null) {
		this.table_id = id;
	}

	// Checkers

	hasCountry() {
		return !isUndefined(this.country_global.id);
	}

	hasStrategy() {
		return Boolean(this.strategy.value);
	}

	hasUsesCases() {
		return Boolean(this.uses_cases.value);
	}

	hasStates() {
		return Boolean(this.geo.selected.states.length > 0);
	}

	hasCities() {
		return Boolean(this.geo.selected.cities.length > 0);
	}

	hasNeighborhoods() {
		return Boolean(this.geo.selected.neighborhoods.length > 0);
	}

	hasCategories() {
		return Boolean(this.pois.selected.categories.length > 0);
	}

	hasSubcategories() {
		return Boolean(this.pois.selected.subcategories.length > 0);
	}

	hasBrands() {
		return Boolean(this.pois.selected.brands.length > 0);
	}

	hasNames() {
		return Boolean(this.pois.selected.names.length > 0);
	}

	hasOOHCategories() {
		return Boolean(this.ooh.selected.ooh_categories.length > 0);
	}

	hasOOHSubCategories() {
		return Boolean(this.ooh.selected.ooh_subcategories.length > 0);
	}

	hasOOHBrands() {
		return Boolean(this.ooh.selected.ooh_brands.length > 0);
	}

	hasOOHNames() {
		return Boolean(this.ooh.selected.ooh_names.length > 0);
	}

	hasGeoSelected() {
		return this.hasElementData(PersonKey.GEO, TypeFilterKey.SELECTED);
	}

	hasPrivateSelected() {
		return this.hasElementData(PersonKey.PRIVATE, TypeFilterKey.SELECTED);
	}

	hasPoisSelected() {
		return this.hasElementData(PersonKey.POIS, TypeFilterKey.SELECTED);
	}

	hasOohSelected() {
		return this.hasElementData(PersonKey.OOH, TypeFilterKey.SELECTED);
	}

	hasPosSelected() {
		return this.hasElementData(PersonKey.POS, TypeFilterKey.SELECTED);
	}

	isCreateAudience() {
		return this.strategy?.type === PersonStrategyKey.CREATE_AUDIENCE;
	}

	isCertainArea() {
		return this.uses_cases?.value === PersonUsesCasesKey.SEEN_CERTAIN_AREA;
	}

	isPosArea() {
		return this.uses_cases?.value === PersonUsesCasesKey.SEEN_POS_AREA;
	}

	showConditional(type: PersonKey) {
		// return [PersonKey.GEO].includes(type) && this.isCertainArea();
		return false;
	}

	showActivateOOHButton() {
		return this.hasGeoSelected() && this.hasOohSelected();
	}

	isSectionAnalizeGeo() {
		return this.isCreateAudience() && this.isCertainArea();
	}

	isSectionAnalizePos() {
		return this.isCreateAudience() && this.isPosArea();
	}

	isSectionAnalizePois() {
		return !this.isSectionAnalizeGeo() && !this.isSectionAnalizePos();
	}

	getAnalizeButtonActionType() {
		if (this.isCertainArea()) return ButtonActionType.ANALYZE_GEO;
		if (this.isPosArea()) return ButtonActionType.ANALYZE_POS;
		return ButtonActionType.ANALYZE_POIS;
	}

	getAudienceType() {
		if (this.isCertainArea()) return PersonKey.GEO;
		if (this.isPosArea()) return PersonKey.POS;
		return PersonKey.POIS;
	}

	getNextSectionFromGeo() {
		if (this.isCertainArea()) return undefined;
		if (this.isPosArea()) return PersonKey.POS;
		return PersonKey.POIS;
	}

	isTypeAudiencePois() {
		if (isNaN(this.uses_cases?.id)) return false;
		return AUDIENCE_TYPE_POIS.includes(this.uses_cases.id);
	}

	isTypeAudienceGeo() {
		if (isNaN(this.uses_cases?.id)) return false;
		return AUDIENCE_TYPE_GEO.includes(this.uses_cases.id);
	}

	isTypeAudiencePos() {
		if (isNaN(this.uses_cases?.id)) return false;
		return AUDIENCE_TYPE_POS.includes(this.uses_cases.id);
	}

	getTypeAudience() {
		let type: ButtonActionType | null = null;

		if (this.isTypeAudiencePois()) type = ButtonActionType.ANALYZE_POIS;
		else if (this.isTypeAudienceGeo()) type = ButtonActionType.ANALYZE_GEO;
		else if (this.isTypeAudiencePos()) type = ButtonActionType.ANALYZE_POS;

		return type;
	}

	isAnalizedPos() {
		return this.response.isValidResponse(ButtonActionType.ANALYZE_POS);
	}

	isAnalizedPois() {
		return this.response.isValidResponse(ButtonActionType.ANALYZE_POIS);
	}

	isAnalizedGeo() {
		return this.response.isValidResponse(ButtonActionType.ANALYZE_GEO);
	}

	isAnalized() {
		const audienceType = this.getTypeAudience();
		if (isNull(audienceType)) return false;

		return this.response.isValidResponse(audienceType);
	}

	// Habilitar el boton de Analize si se cumple con la condicion
	enableAnalize() {
		const hasCountryUseCase: Boolean =
			this.hasCountry() && this.hasStrategyAndUseCases();

		if (!hasCountryUseCase) return false;

		const tabs = this.visibility.tabs;

		let hasFilters: Boolean = hasCountryUseCase;

		if (tabs.includes(PersonKey.GEO)) {
			hasFilters = hasFilters && this.hasGeoSelected();
		}

		if (tabs.includes(PersonKey.POIS)) {
			hasFilters = hasFilters && this.hasPoisSelected();
		}

		if (tabs.includes(PersonKey.PRIVATE)) {
			hasFilters = hasFilters || this.hasPrivateSelected();
		}

		if (tabs.includes(PersonKey.OOH)) {
			hasFilters = hasFilters || this.hasOohSelected();
		}

		if (tabs.includes(PersonKey.POS)) {
			hasFilters = hasFilters && this.hasPosSelected();
		}

		return hasFilters;
	}

	hasStrategyAndUseCases() {
		const strategyType: string | undefined = this.strategy.type;
		const useCaseName: string | undefined = this.uses_cases.value;

		const hasStrategyType: Boolean = !isEmpty(strategyType);

		const hasUseCaseName: Boolean = !isEmpty(useCaseName);

		return hasStrategyType && hasUseCaseName;
	}

	// Actions

	/**
	 * Toggle Select All
	 * @param key "country_global|audience_type|geo"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param elements "{id,value}[]"
	 */
	async toggleSelectAll(
		key: PersonKeyof,
		type: GeoFilterDataKeyof,
		filter: GeoKeyof,
		elements: ElementData[]
	) {
		let elems: ElementData[] = elements;

		const elemUniq: ElementData[] = [...new Set(elems)] as ElementData[];

		this[key][type][filter] = elemUniq;

		if (type === ("selected" as GeoFilterDataKeyof)) {
			this[key]["pre"][filter] = elemUniq;
		}

		this[key]["and"][filter] = [];
	}

	/**
	 * Toggle Selected All
	 * @param params
	 * @returns
	 */
	async toggleSelectedAll(params: {
		key: PersonKeyof;
		type: TypeFilterKey;
		all: Boolean;
		filter: PersonFilterType;
		elements: ElementData[];
	}) {
		const { key, type, filter, all, elements } = params;

		let selectedData: ElementData[] = this[key][type][filter];

		let mergedSelectedData: ElementData[] = [] as ElementData[];

		if (all) {
			mergedSelectedData = selectedData.concat(elements);
			// Filtrar elementos duplicados
			mergedSelectedData = this.uniqueElements(mergedSelectedData);
		} else {
			mergedSelectedData = selectedData.filter(
				(s) => !this.hasSomeElementData(elements, s)
			);
		}

		if (type === TypeFilterKey.SELECTED) {
			// this[key]["pre" as GeoFilterDataKeyof][filter] = mergedSelectedData;
			if (!HIDE_VIEW_CLEAR_FILTERS.includes(filter)) {
				this[key][TypeFilterKey.PRE][filter] = mergedSelectedData;
			}
		}

		this[key][type][filter] = mergedSelectedData;
		this[key][TypeFilterKey.AND][filter] = [] as ElementData[];
	}

	/**
	 *
	 * @param params
	 */
	async togglePreFilter(params: {
		key: PersonKey;
		filter: PersonFilterType;
		toggle: Boolean;
		element: ElementData;
	}) {
		const { key, filter, toggle, element } = params;

		// Get the existing elements
		let elements: ElementData[] = this[key][TypeFilterKey.PRE][filter];

		// Toggle the element presence based on the toggle value
		if (toggle) {
			elements.push(element);
		} else {
			elements = elements.filter((i) => !this.isEqualIdent(i, element));
			await this.cleanFilterChilds(
				key,
				TypeFilterKey.PRE,
				filter,
				element
			);
		}

		// Removing duplicate elements
		const uniqueElements: ElementData[] = [
			...new Set(elements),
		] as ElementData[];

		if (!HIDE_VIEW_CLEAR_FILTERS.includes(filter)) {
			this[key][TypeFilterKey.PRE][filter] = uniqueElements;
		}
	}

	/**
	 *
	 * @param key "country_global|audience_type|geo"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param element "{id,value}"
	 */
	async updatePreFilter(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		element: ElementData
	) {
		let elems: ElementData[] = this[key][type][filter];

		if (this.hasSomeElementData(elems, element)) {
			elems = elems.filter((i) => !this.isEqualIdent(i, element));
			await this.cleanFilterChilds(key, type, filter, element);
		} else {
			elems.push(element);
		}

		const elemUniq: ElementData[] = [...new Set(elems)] as ElementData[];

		this[key][type][filter] = elemUniq;
	}

	async cleanPreSeleccion(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		field: MatchFilter,
		compare: ElementData
	) {
		let elements: ElementData[] = this[key][type][filter];
		this[key][type][filter] = elements.filter(
			(s) => s[field] !== compare.value
		);
	}

	async cleanAllFilterChilds(filter: PersonGeoKey | PersonPoisKey) {
		if (!FILTER_KEY_MAPPING.pois.includes(filter as PersonPoisKey)) return;

		this.pois.pre.names = [];

		if (
			[PersonPoisKey.CATEGORIES, PersonPoisKey.SUBCATEGORIES].includes(
				filter as PersonPoisKey
			)
		) {
			this.pois.pre.brands = [];
		}

		if (filter !== PersonPoisKey.CATEGORIES) return;

		this.pois.pre.subcategories = [];
	}

	async cleanFilterChilds(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonFilterType,
		element: ElementData
	) {
		if (!FILTER_KEY_MAPPING.pois.includes(filter as PersonPoisKey)) return;

		// Names are always cleaned
		this.cleanPreSeleccion(
			key,
			type,
			PersonPoisKey.NAMES,
			MatchFilter[filter],
			element
		);

		if (
			FILTER_KEY_MAPPING.pois
				.slice(0, 2)
				.includes(filter as PersonPoisKey)
		) {
			// Brands are cleaned only if filter is either categories or subcategories
			this.cleanPreSeleccion(
				key,
				type,
				PersonPoisKey.BRANDS,
				MatchFilter[filter],
				element
			);
		}

		if (filter === PersonPoisKey.CATEGORIES) {
			// Subcategories are cleaned only if filter is categories
			this.cleanPreSeleccion(
				key,
				type,
				PersonPoisKey.SUBCATEGORIES,
				MatchFilter[filter],
				element
			);
		}
	}

	mergeElements(elements1: ElementData[], elements2: ElementData[]) {
		let set = new Set([...elements1, ...elements2]);
		let mergedArray = [...set];
		return mergedArray;
	}

	uniqueElements(elements: ElementData[]) {
		return elements.filter(
			(item, index, self) =>
				self.findIndex((i) => this.isEqualIdent(i, item)) === index
		);
	}

	addDeleteElement(elements: ElementData[], element: ElementData) {
		const index = elements.findIndex((item) =>
			this.isEqualIdent(item, element)
		);

		if (index !== -1) {
			// El elemento ya existe en el elements, eliminarlo
			elements.splice(index, 1);
		} else {
			// El elemento no existe en el elements, agregarlo
			elements.push(element);
		}

		// Filtrar elementos duplicados
		return this.uniqueElements(elements);
	}

	/**
	 *
	 * @param key "country_global|audience_type|geo|pois|privates|ooh"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param element "{id,value}"
	 */
	async updateFilter(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonFilterType,
		element: ElementData
	) {
		let filterSelecteds: ElementData[] = this[key][type][filter];

		this[key][type][filter] = this.addDeleteElement(
			filterSelecteds,
			element
		);
	}

	addSelectedState(key: string, element: ElementData) {
		let elements: ElementData[] = this.geo.selected[key];
		if (elements.includes(element)) {
			this.geo.selected[key] = elements.filter(
				(i) => !this.isEqualIdent(i, element)
			);
			return;
		}
		this.geo.selected[key].push(element);
		const uniqueElements = [...new Set(this.geo.selected[key])];
		this.geo.selected[key] = uniqueElements;
	}

	/**
	 * Set New filters
	 * @param key "PersonKey"
	 * @param filter "states|cities|neighborhoods"
	 * @param values "[string,string]"
	 */
	async setNewFilters(
		key: PersonKey,
		filter: PersonFilterType,
		values: string[]
	) {
		let selected: ElementData[] = this[key][TypeFilterKey.SELECTED][filter];
		let pre: ElementData[] = this[key][TypeFilterKey.PRE][filter];

		// No actualizar si el valor es undefined
		if (isUndefined(values)) return;

		if (key !== PersonKey.PRIVATE) {
			selected = values.map(
				(item) =>
					new SelectedDataEntity({
						id: NaN,
						value: item,
					})
			);
			pre = values.map(
				(item) =>
					new SelectedDataEntity({
						id: NaN,
						value: item,
					})
			);
		} else {
			selected = selected.filter((s) => values.includes(s.value));
			pre = pre.filter((s) => values.includes(s.value));
		}

		this[key][TypeFilterKey.SELECTED][filter] = selected;
		this[key][TypeFilterKey.PRE][filter] = pre;
	}

	/**
	 * Draggend From
	 * @param key "country_global|audience_type|geo"
	 * @param type "filters|pre|selected|and"
	 * @param element "{id,value}"
	 */
	draggendFilter(type: PersonKeyof, key: GeoFilterDataKeyof, element: any) {
		const elementData: ElementData = {
			id: element.id,
			value: element.value,
			count: element.count,
		};
		let elems: ElementData[] = this[type][key][element.key];
		elems.push(elementData);
		const uniqueElements = [...new Set(elems)];
		this[type][key][element.key] = uniqueElements;
	}

	/**
	 *
	 * Remove From Selected
	 * @param element
	 * @param personKey
	 * @returns
	 */
	async removeFromSelected(
		element: ElementData,
		personKey: PersonKey,
		filterType?: TypeFilterKey
	) {
		if (PersonKey.COUNTRY_GLOBAL === personKey) {
			this.country_global = new SelectedDataEntity();
			return;
		}

		if (PersonKey.STRATEGY === personKey) {
			this.strategy = new SelectedDataEntity();
			return;
		}

		if (PersonKey.USES_CASES === personKey) {
			this.uses_cases = new SelectedDataEntity();
			return;
		}

		if (FILTER_SECTIONS.includes(personKey)) {
			if (!filterType) return;

			if (AssignIDs.includes(element.id)) {
				this.clearAllFilterByType(personKey);
			}

			this.filterElementDataUnique(element, personKey, filterType);

			if (personKey === PersonKey.POS) {
				this.filterElementDataUnique(
					element,
					PersonKey.GEO,
					filterType
				);
			}

			if (filterType === TypeFilterKey.SELECTED) {
				if (personKey === PersonKey.POS) {
					this.filterElementDataUnique(
						element,
						PersonKey.GEO,
						TypeFilterKey.PRE
					);
				}

				this.filterElementDataUnique(
					element,
					personKey,
					TypeFilterKey.PRE
				);
			}

			const reset: Boolean =
				personKey === PersonKey.GEO && !this.hasGeoSelected();

			if (reset) {
				this.resetElementDataByKey(
					PersonKey.POIS,
					TypeFilterKey.SELECTED
				);
				this.resetElementDataByKey(PersonKey.POIS, TypeFilterKey.PRE);
			}
		}
	}

	/**
	 * Filter Element Data by [key]
	 * @param element ElementData
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 */
	filterElementDataUnique(
		element: ElementData,
		personKey: PersonKey,
		filterType: TypeFilterKey
	) {
		let filters = Object.entries(this[personKey][filterType]);
		for (const [filterKey, filter] of filters) {
			let filtered: ElementData[] = (filter as ElementData[]).filter(
				(f) => !this.isEqualIdent(f, element)
			);
			const uniqueElements = [...new Set(filtered)];
			this[personKey][filterType][filterKey] = uniqueElements;
		}
	}

	/**
	 * Delete elementData from filters
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 * @param filterKey
	 * @param element
	 */
	deleteElementData(
		personKey: PersonKey, // geo|pois
		filterType: TypeFilterKey, //selected|pre|and
		filterKey: PersonGeoKey | PersonPoisKey | PersonOohKey | PersonPosKey, // states|cities|neighborhoods
		element: ElementData
	) {
		let filters: ElementData[] = this[personKey][filterType][filterKey];
		let filtered: ElementData[] = filters.filter(
			(f) => !this.isEqualIdent(f, element)
		);
		const uniqueElements = [...new Set(filtered)];
		this[personKey][filterType][filterKey] = uniqueElements;
	}

	/**
	 *
	 * Reset All Element Data
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 */
	resetElementDataByKey(personKey: string, filterType: string) {
		let filters = Object.entries(this[personKey][filterType]);
		this.table_id = null;
		for (const [filterKey] of filters) {
			this[personKey][filterType][filterKey] = [] as ElementData[];
		}
	}

	/**
	 * Has any filters geo|pois
	 * @param personKey
	 * @param filterType
	 * @returns
	 */
	hasElementData(personKey: PersonKey, filterType: TypeFilterKey) {
		let filters = Object.values(this[personKey][filterType]);
		return filters?.some((value) => !isEmpty(value));
	}

	/**
	 * Has some element by identifier & id
	 * @param elements
	 * @param item
	 * @returns
	 */
	hasSomeElementData(elements: ElementData[], item: ElementData) {
		return elements.some((e) => e.identifier === item.identifier);
	}

	/**
	 * Compare elementData by identifier
	 * @param item
	 * @param compare
	 * @returns
	 */
	isEqualIdent(item: ElementData, compare: ElementData) {
		return item.identifier === compare.identifier;
	}

	/**
	 * Resetear los valores seleccionados
	 * valores opcionales {strategy,uses_cases,geo}
	 * @param reset
	 */
	changeTabSelection(reset?: TabSelectionOptional) {
		if (reset?.strategy) {
			this.strategy = new SelectedDataEntity();
		}
		if (reset?.uses_cases) {
			this.uses_cases = new SelectedDataEntity();
		}
		if (reset?.geo) {
			this.geo = new GeoFilterDataEntity();
		}
		if (reset?.pois) {
			this.pois = new PoisFilterDataEntity();
		}
		if (reset?.ooh) {
			this.ooh = new OohFilterDataEntity();
		}
		if (reset?.privates) {
			this.privates = new PrivateFilterDataEntity();
		}
		if (reset?.pos) {
			this.pos = new PosFilterDataEntity();
		}

		this.changeFilters();
	}

	async changeFilters() {
		this.table_id = null;
		this.audience = new PersonAudienceEntity();
		this.store_attribution = new PersonStoreAttributionEntity();
		this.loading = new ActionLoadingEntity();
		this.response = new ActionResponseEntity();
		this.graphics = new GraphicResponseEntity();

		console.debug(`changeFilters synchronizing_filters: ${this.synchronizing_filters}`);

		if(!this.synchronizing_filters) {
			this.pos.selected.resetQuintils();
		}
	}

	keyExistsInActivePanelTab(key: string): boolean {
		return Object.keys(ActivePanelTab).includes(key);
	}

	omitNext() {
		return [
			PersonKey.GEO,
			PersonKey.POIS,
			PersonKey.PRIVATE,
			PersonKey.OOH,
			PersonKey.POS,
		];
	}

	/**
	 *
	 * Buscar proximo tab para abrir: {activePanel}
	 * Buscar proximo tab para activar: enable
	 *
	 * @param personKey
	 * @returns
	 */
	async findNextTabFactory(
		personKey: PersonKey
	): Promise<ActivePanelNextEntity> {
		let tabFinded = this.visibility.tabs.findIndex((t) => t === personKey);
		let index = tabFinded + 1;

		let activable = !this.omitNext().includes(personKey);

		const next: ActivePanelNextEntity = new ActivePanelNextEntity({
			enable: this.visibility.tabs[index],
			activePanel: activable ? index : undefined,
		});

		return next;
	}

	/**
	 * Actualizar la visibilidad de
	 * las secciones de filtros y los botones de accion
	 * segun la seleccion de {strategy} y {uses_cases}
	 */
	async updateVisibility() {
		let strategyType = this.strategy.type || PersonStrategyKey.NONE;
		let useCaseValue = this.uses_cases.value || PersonUsesCasesKey.NONE;
		let USE_CASES = searchVisibility(strategyType, useCaseValue);
		await this.visibility.setVisibility(USE_CASES);
	}

	/**
	 * Obtener proximo boton deshabilitado
	 * @param type
	 * @returns
	 */
	async getNexButtonAction(type: ButtonActionType) {
		let btnFinded = this.visibility.buttons.findIndex((t) => t === type);
		let index = btnFinded + 1;
		return this.visibility.buttons[index];
	}

	async matchedNextActiveTab() {
		let matched = {
			//COUNTRY_GLOBAL
			[`${ActivePanelTab.COUNTRY_GLOBAL}_${PersonKey.COUNTRY_GLOBAL}`]:
				await this.findNextTabFactory(PersonKey.COUNTRY_GLOBAL),

			//STRATEGY
			[`${ActivePanelTab.STRATEGY}_${PersonKey.STRATEGY}`]:
				await this.findNextTabFactory(PersonKey.STRATEGY),

			//USES_CASES
			[`${ActivePanelTab.USES_CASES}_${PersonKey.USES_CASES}`]:
				await this.findNextTabFactory(PersonKey.USES_CASES),

			//GEO
			// [`${ActivePanelTab.GEO}_${PersonKey.GEO}`]:
			// 	await this.findNextTabFactory(PersonKey.GEO),
		};

		return matched;
	}

	async getNextTab(
		activePanel: ActivePanelTab,
		personKey: PersonKey
	): Promise<ActivePanelNextEntity> {
		const matchedTabs = await this.matchedNextActiveTab();
		const keyPrepared = `${activePanel}_${personKey}`;
		return matchedTabs[keyPrepared];
	}

	notValidCountryNext(activePanel: ActivePanelTab) {
		return (
			activePanel === ActivePanelTab.COUNTRY_GLOBAL && !this.hasCountry()
		);
	}

	notValidStrategyNext(activePanel: ActivePanelTab) {
		return activePanel === ActivePanelTab.STRATEGY && !this.hasCountry();
	}

	notValidUsesCasesNext(activePanel: ActivePanelTab) {
		return activePanel === ActivePanelTab.USES_CASES && !this.hasStrategy();
	}

	notValidGeoNext(activePanel: ActivePanelTab) {
		return activePanel === ActivePanelTab.GEO && !this.hasUsesCases();
	}

	notValidPoisNext(activePanel: ActivePanelTab) {
		return activePanel === ActivePanelTab.POIS && !this.hasGeoSelected();
	}

	notValidPosNext(activePanel: ActivePanelTab) {
		return (
			activePanel === ActivePanelTab.POS && !this.hasStrategyAndUseCases()
		);
	}

	/**
	 * Verificar si los filtros en cada tab es valido
	 * si es valido cierra el tab actual y abre el proximo
	 * @param activePanel // panel activo
	 * @param personKey // seccion en donde se esta disparando la accion: watch
	 * @returns
	 */

	async verifyActivePanel(activePanel: ActivePanelTab, personKey: PersonKey) {
		let nextTab: ActivePanelNextEntity = new ActivePanelNextEntity();

		switch (personKey) {
			case PersonKey.COUNTRY_GLOBAL:
				if (this.notValidCountryNext(activePanel)) {
					return nextTab;
				}
				if (!activePanel) {
					nextTab.setActivePanel(ActivePanelTab.STRATEGY);
					return nextTab;
				}
				break;

			case PersonKey.STRATEGY:
				if (this.notValidStrategyNext(activePanel)) {
					nextTab.setActivePanel(ActivePanelTab.COUNTRY_GLOBAL);
					return nextTab;
				}
				break;

			case PersonKey.USES_CASES:
				if (this.notValidUsesCasesNext(activePanel)) {
					nextTab.setActivePanel(ActivePanelTab.STRATEGY);
					return nextTab;
				}
				break;

			case PersonKey.GEO:
				if (this.notValidGeoNext(activePanel)) {
					return nextTab;
				}
				break;

			case PersonKey.POIS:
				if (this.notValidPoisNext(activePanel)) {
					return nextTab;
				}
				break;

			case PersonKey.POS:
				if (this.notValidPosNext(activePanel)) {
					return nextTab;
				}
				break;
		}

		nextTab = await this.getNextTab(activePanel, personKey);

		/**
		 * Habilitar para [debug]
		 */
		// if (nextTab?.hasData()) {
		// 	nextTab.debugLog();
		// }

		return nextTab;
	}

	/**
	 * Clear Filter
	 * @param personKey
	 * @param filterType
	 * @param filterKey
	 */
	clearFilter(
		personKey: PersonKey, // geo|pois
		filterType: TypeFilterKey, //selected|pre|and
		filterKey: PersonGeoKey | PersonPoisKey // states|cities|neighborhoods
	) {
		this[personKey][filterType][filterKey] = [] as ElementData[];

		if (filterType !== TypeFilterKey.PRE) return;
		this.cleanAllFilterChilds(filterKey);
	}

	/**
	 * Clear All Filters by type GEO|POIS
	 * @param type
	 * @returns
	 */
	clearAllFilterByType(type: PersonKey) {
		switch (type) {
			case PersonKey.GEO:
				this.geo = new GeoFilterDataEntity();
				this.pois = new PoisFilterDataEntity();
				this.pos = new PosFilterDataEntity();
				this.select_all[type] = new SelectAllGeoEntity();
				this.select_all[type] = new SelectAllGeoEntity();
				break;

			case PersonKey.POIS:
				this.pois = new PoisFilterDataEntity();
				this.select_all[type] = new SelectAllPoisEntity();
				this.select_all[PersonKey.GEO] = new SelectAllGeoEntity();
				break;

			case PersonKey.PRIVATE:
				this.privates = new PrivateFilterDataEntity();
				this.select_all[type] = new SelectAllPrivateEntity();
				break;

			case PersonKey.OOH:
				this.ooh = new OohFilterDataEntity();
				this.select_all[type] = new SelectAllOhhEntity();
				break;

			case PersonKey.POS:
				this.geo = new GeoFilterDataEntity();
				this.pos = new PosFilterDataEntity();
				this.select_all[type] = new SelectAllPosEntity();
				break;
		}
	}
}
