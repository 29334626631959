import ProfileService, { ProviderProfile } from "@/services/profile-service";
import AccountService from "@/services/account-service";
import { catchError } from "@/store/Util";
import { Profile } from "@/interfaces/user";
import { Commit } from "vuex";
import defineAbility from '@/config/ability.ts';

export const ProfileModule = {
	namespaced: true,
	state: () => ({
		profile: {} as Profile,
		account: null,
		lookFeel: null,
		permission: {},
		ability: {},
		forbidden_accounts: [300] //SE INCLUYE LA CUENTA DE BLASTER PARA OCULTAR BOTONES DEL MENU Y PERSONALIZAR VISTAS!!
	}),
	mutations: {
		SET_PROFILE(state: any, _profile: Profile = {} as Profile) {
			state.profile = _profile;
		},
		SET_ACCOUNT(state: any, _account = null) {
			state.account = _account;
		},
		SET_LOOK_FEEL(state: any, _lookFeel = null) {
			state.lookFeel = _lookFeel;
		},
		SET_PROFILE_LANGUAGE(state: { profile: Profile }, language: string) {
			state.profile.language = language;
		},
		SET_PERMISSION(state: any, _permission: any) {
			state.permission = _permission;
		},
		SET_ABILITY(state: any, _ability: any) {
			state.ability = _ability;
		},
		UPDATE_PROFILE<T>(
			state: { profile: Profile },
			data: { key: string; value: T }
		) {
			state.profile[data.key] = data.value;
		},
	},
	getters: {
		profile(state: { profile: Profile }) {
			return state.profile;
		},
		getCurrentLanguage(state: { profile: Profile }) {
			return state.profile.language;
		},
		getForbiddenAccounts(state: any) {
			return state.forbidden_accounts;
		},
		account(state: any) {
			return state.account;
		},
		lookFeel(state: any) {
			return state.lookFeel;
		},
		logo(state: any) {
			return state.profile?.logo;
		},
		isRolReport(state: any) {
			return false;
		},
		getPermission(state: any) {
			return state.permission;
		},
		getAbility(state: any) {
			return state.ability;
		},
		getProfileData(state: { profile: Profile }) {
			return state.profile;
		},
		getColors(state: { profile: Profile }){
			return state.profile.colors;
		}
	},
	actions: {
		async fetchProfile({ commit }) {
			try {
				const response = await ProfileService.profile();
				const account = await AccountService.account();
				commit("SET_PROFILE", ProviderProfile(response, account));
				commit("SET_ACCOUNT", account);
				commit("SET_PERMISSION", response.permissions);

				const ability = defineAbility();
				commit("SET_ABILITY", ability);

				/*
				const permission = {
					"Menu": [
						"menu_planning",
						"menu_private_pois",
						"menu_advertiser",
						"menu_campaign",
						"menu_line_item",
						"menu_creative",
						"menu_reporting",
						"menu_direct_reports",
						"menu_reports_on_demand",
						"menu_dashboard",
						"menu_cpi_campaign",
						"menu_tools",
						"menu_list",
						"menu_modifier",
						"menu_segment",
						"menu_segment_category",
						"menu_segment_upload",
						"menu_one_link_test",
						"menu_users",
						"menu_event"
					],
					"User": [
						"index_user",
						"show_user",
						"create_user",
						"update_user",
						"delete_user"
					],
					"Notification": [
						"index_notification",
						"create_notification",
						"update_notification"
					],
					"Advertiser": [
						"index_advertiser",
						"create_advertiser",
						"show_advertiser",
						"update_advertiser",
						"set_active_advertiser",
						"audit_advertiser"
					],
					"CustomList": [
						"index_custom_list",
						"create_custom_list",
						"show_custom_list",
						"update_custom_list",
						"set_active_custom_list",
						"clone_custom_list",
						"create_custom_list_item",
						"index_custom_list_item",
						"update_custom_list_item",
						"delete_custom_list_item",
						"set_active_custom_list_item"
					],
					"Campaign": [
						"index_campaign",
						"create_campaign",
						"show_campaign",
						"update_campaign",
						"delete_campaign",
						"set_active_campaign",
						"clone_campaign",
						"audit_campaign",
						"create_event_association_campaign"
					],
					"LineItem": [
						"index_line_item",
						"create_line_item",
						"show_line_item",
						"update_line_item",
						"delete_line_item",
						"set_active_line_item",
						"clone_line_item",
						"audit_line_item",
						"create_event_association_line_item"
					],
					"Targeting": [
						"index_targeting",
						"create_targeting",
						"show_targeting",
						"update_targeting"
					],
					"Event": [
						"index_event",
						"create_event",
						"show_event",
						"update_event",
						"delete_event",
						"create_campaign_association",
						"create_line_item_association",
						"show_event_tag",
						"set_active_event"
					],
					"Report": [
						"create_report",
						"create_report_schedule",
						"index_report_schedule",
						"show_report_schedule",
						"update_report_schedule",
						"delete_report_schedule",
						"set_active_report_schedule",
						"index_report_on_demand",
						"create_report_on_demand_store_attribution",
						"create_report_on_demand_geo",
						"create_report_on_demand_campaign_insight",
						"create_report_on_demand_unique_devices",
						"download_report_on_demand"
					],
					"List": [
						"list_timezones",
						"list_advertiser_categories",
						"list_currencies",
						"list_custom_list_types",
						"list_roles",
						"list_budget_types",
						"list_optimization_strategies",
						"list_strategies",
						"list_campaign_pacing",
						"list_kpi_campaign",
						"list_bid_shadings",
						"list_custom_list_exchanges",
						"list_countries",
						"list_regions",
						"list_cities",
						"list_custom_list",
						"list_location_types",
						"list_ad_positions",
						"list_environment_types",
						"list_auction_types",
						"list_interstitial_flags",
						"list_interstitial_types",
						"list_inventory_sources",
						"list_video_apis",
						"list_native_layouts",
						"list_creative_sizes",
						"list_content_categories",
						"list_content_ratings",
						"list_languages",
						"list_companion_support",
						"list_video_playback_methods",
						"list_video_player_sizes",
						"list_video_start_delays",
						"list_video_placement_types",
						"list_bandwidths",
						"list_browsers",
						"list_browsers_versions",
						"list_carriers",
						"list_device_makes",
						"list_device_models",
						"list_device_screen_sizes",
						"list_devices",
						"list_operating_systems",
						"list_operating_system_versions",
						"list_modifier_types",
						"list_modifier_modules",
						"list_matching_types",
						"list_verb_usages",
						"list_segment_providers",
						"list_segment_categories",
						"list_segments",
						"list_model_types",
						"list_targeting_location_types",
						"list_targeting_modules",
						"list_targeting_predicates",
						"list_topframes",
						"list_rewarded",
						"list_unit_times",
						"list_creative_types",
						"list_creative_templates",
						"list_creative_bulk_templates",
						"list_creative_rules",
						"list_creative_bulk_rules",
						"list_in_banner_videos",
						"list_mime_types",
						"list_audio_mime_types",
						"list_expandable_types",
						"list_expandable_directions",
						"list_video_bit_rates",
						"list_creative_vendors",
						"list_line_items_types",
						"list_bid_strategy",
						"list_line_pacing",
						"list_creative_methods",
						"list_addon_types",
						"list_app_name",
						"list_site_id",
						"list_asset_types",
						"list_vast_events",
						"list_video_encodings",
						"list_segment_tag_types",
						"extract_vast_attributes",
						"list_continents",
						"list_campaign_owners",
						"list_report_types",
						"list_report_data_range",
						"list_report_fields",
						"list_report_format_types",
						"list_report_advertisers",
						"list_report_campaigns",
						"list_report_line_items",
						"list_report_creatives",
						"list_report_list",
						"list_ext_campaign_providers",
						"list_ext_campaign_types",
						"list_buy_models",
						"list_ext_campaign_mmp",
						"list_ext_campaign_owners",
						"list_campaign_status",
						"list_ext_creative_size",
						"list_ext_creative_aspect_ratio",
						"list_ext_creative_type",
						"list_internal_deal_ids",
						"list_frequency_cap_type",
						"list_on_demand_countries",
						"list_on_demand_types",
						"list_translation_languages",
						"list_event_types",
						"list_event_attributions",
						"list_event_tag_type"
					],
					"Modifier": [
						"index_modifier",
						"show_modifier",
						"create_bid_modifier",
						"update_bid_modifier",
						"create_delivery_modifier",
						"update_delivery_modifier"
					],
					"Segment": [
						"index_segment",
						"create_segment",
						"show_segment",
						"update_segment",
						"delete_segment",
						"create_segment_upload",
						"index_segment_upload",
						"index_segment_third"
					],
					"BidModel": [
						"index_bid_model",
						"create_bid_model",
						"show_bid_model",
						"update_bid_model"
					],
					"Creative": [
						"index_creative_addon",
						"create_creative_addon",
						"show_creative_addon",
						"update_creative_addon",
						"index_creative_asset",
						"show_creative_asset",
						"create_creative_asset",
						"index_creative",
						"create_creative",
						"show_creative",
						"update_creative",
						"set_active_creative",
						"index_creative_line_association",
						//"create_creative_line_association",
						//"update_creative_line_association",
						//"delete_creative_line_association",
						"create_creative_bulk",
						"clone_creative",
						"audit_creative",
						"delete_creative"
					],
					"CpiCampaign": [
						"index_cpi_campaign",
						"create_cpi_campaign",
						"show_cpi_campaign",
						"update_cpi_campaign",
						"index_cpi_campaign_note",
						"create_cpi_campaign_note",
						"show_cpi_campaign_note",
						"index_cpi_creative",
						"show_cpi_creative",
						"create_cpi_creative",
						"update_cpi_creative",
						"send_cpi_campaign_mail"
					],
					"Planning": [
						"index_planning"
					]
				}
				commit("SET_PERMISSION", permission);*/
				
				return await Promise.resolve(
					ProviderProfile(response, account)
				);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async fetchLookAndFeel({ commit }: any, params: { domain: string }) {
			try {
				const response = await ProfileService.getLookAndFeelLogin(
					params
				);
				commit("SET_LOOK_FEEL", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async setProfileLanguage(
			{ commit }: { commit: Commit },
			language: string
		) {
			return commit("SET_PROFILE_LANGUAGE", language);
		},
		async setProfileData<T>(
			{ commit }: { commit: Commit },
			data: { key: string; value: T }
		) {
			return commit("UPDATE_PROFILE", data);
		},
		async setAbility({ commit }, ability) {
			try {
				return commit("SET_ABILITY", ability);
			} catch (error) {
				
			}
		},
		isAccountAuthorized({ commit, state }, accountID) {
			try {
				return !state.forbidden_accounts.includes(accountID);
			} catch (error) {
				
			}
		},
	},
};
